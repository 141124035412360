import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CareerMail from "../../src/img/career/career-mail.svg";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const CareersThree = ({ careers3data, careers1data }) => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = (e) => {
    const elements = document.querySelectorAll(".careers_heading");

    elements.forEach((element) => {
      element.innerText = "Submit your CV";
    });
    const formElement = document.getElementById("careerSubmit");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };
  useEffect(() => {
    if (careers1data?.section_setting === true) {
      setIsLoading(false);
    }
  }, [careers1data?.section_setting]);
  return (
    <>
      {careers1data?.section_setting === true && (
        <section className="job-section section-spacing pb-0">
          <div className="container pt-3 pt-md-0">
            <div className="row align-items-center">
              <div className="col-md-6">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="Career-content-inner">
                      <h3>{careers1data.txt_img_sec_sub_title}</h3>
                      <div
                        className="page-title"
                        dangerouslySetInnerHTML={{
                          __html: careers1data.txt_img_sec_description,
                        }}
                      ></div>
                      <div className="d-flex  flex-wrap career-inner-icon mt-3 pt-3">
                        <Link
                          to={careers1data?.email_address?.url}
                          className="d-flex career-mail"
                        >
                          <img src={CareerMail} className="img-fluid" alt="" />
                          <p>{careers1data?.email_address?.title}</p>
                        </Link>
                      </div>
                    </div>
                    <div className="carrer-team">
                      <div className="career-aplly mt-md-4 mt-2">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#FormModal"
                          onClick={handleClose}
                        >
                          {
                            careers3data?.image_with_right_text[0]?.button_link
                              ?.title
                          }
                        </Link>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-6">
                <div className="life-doodle">
                  {isLoading ? (
                    <Imgloader />
                  ) : (
                    <>
                      <img
                        src={
                          careers3data?.image_with_right_text[0]?.left_image?.url
                        }
                        className="img-fluid doodles-image"
                        alt={careers1data.txt_img_sec_sub_title}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CareersThree;
