import React from 'react'
import { Link } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div class="pt-5 pb-md-0 pb-0">
      <div class="container pt-5 pb-5">
        <div class="row gx-md-5 pt-5 pb-md-5 align-items-center">
          <div class="col-12 text-center">
            <p class="list-link">404 - Not Found</p>
            <h2 class="fw-bold page-title mb-5">The page you're looking for doesn't exist.</h2>
            <Link to="/" className='btn btn-primary error-page-btn'>Go to Homepage</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
