import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiCall, imageUpdate } from "../authentication";
const Rightside = () => {
  let [stickyFooter, setStickyFooter] = useState([]);
  const slug1 = "options/all";
  const type = "get";
  const fetchTestimonialsYoutube = async () => {
    try {
      const data = await apiCall(slug1, type);
      setStickyFooter(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTestimonialsYoutube();
  }, []);

  const [isDarkMode, toggleTheme] = useState("light");

  const handleToggleTheme = () => {
    const newMode = isDarkMode === "light" ? "dark" : "light";
    toggleTheme(newMode);
    changeMode(newMode);
  };

  const changeMode = (isDarkMode) => {
    if (isDarkMode === "light") {
      localStorage.setItem("siteTheme", isDarkMode);
      document.body.classList.add("light-mode");
      document.body.classList.remove("dark-mode");
    } else {
      localStorage.setItem("siteTheme", isDarkMode);
      document.body.classList.add("dark-mode");
      document.body.classList.remove("light-mode");
    }
    imageUpdate();
  };

  useEffect(() => {
    const handleStorageChange = () => {
      var siteTheme = localStorage.getItem("siteTheme");
      if (siteTheme !== null) {
        if (siteTheme === "light") {
          toggleTheme("light");
          document.body.classList.add("light-mode");
          document.body.classList.remove("dark-mode");
        } else {
          toggleTheme("dark");
          document.body.classList.add("dark-mode");
          document.body.classList.remove("light-mode");
        }
      } else {
        toggleTheme("light");
        document.body.classList.add("light-mode");
        document.body.classList.remove("dark-mode");
      }
      imageUpdate();
    }
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);



  return (
    <div className="right_section">
      <div class="social-icons-box op_0" style={{ opacity: "1" }}>
        <div class="social-icons">
          {/* <button onClick={handleToggleTheme} type="button" className="btn">
            {isDarkMode ? (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4c9bff" class="bi bi-moon-stars-fill" viewBox="0 0 16 16"><path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" /><path d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.73 1.73 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.73 1.73 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.73 1.73 0 0 0 1.097-1.097zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" /></svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4c9bff" class="bi bi-brightness-high-fill" viewBox="0 0 16 16"><path d="M12 8a4 4 0 1 1-8 0 4 4 0 0 1 8 0M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" /></svg>)}
          </button> */}
          {stickyFooter &&
            stickyFooter?.sticky_sidebar_links &&
            stickyFooter?.sticky_sidebar_links.map((stickyLink, index) => (
              <Link
                rel="noopener"
                aria-label="Facebook"
                to={stickyLink?.sidebar_link?.url}
                target="_blank"
                class="social-icon fb"
              >
                <img
                  src={stickyLink?.sidebar_icon}
                  alt={stickyLink?.sidebar_icon}
                />
              </Link>
            ))}
        </div>
      </div>
      <Link
        aria-label="Contact us"
        to={stickyFooter?.get_in_touch_details?.[0]?.get_in_touch_link?.url}
        class="get-in-touch-box my-2 d-block"
        style={{ opacity: "1" }}
      >
        <div class="get-in-touch">
          <div class="get-in-touch-icon mb-2">
            {stickyFooter && stickyFooter?.get_in_touch_details && (
              <img
                src={stickyFooter?.get_in_touch_details[0]?.get_in_touch_icon}
                alt={stickyFooter?.get_in_touch_details[0]?.get_in_touch_icon}
              />
            )}
          </div>
          {stickyFooter && stickyFooter?.get_in_touch_details && (
            <div class="get-in-touch-text">
              <span>
                {stickyFooter?.get_in_touch_details[0]?.get_in_touch_link.title}
              </span>
            </div>
          )}
        </div>
      </Link>
      <div class="social-icons-box op_0" style={{ opacity: "1" }}>
        <div class="social-icons">
          {stickyFooter &&
            stickyFooter?.social_links_details &&
            stickyFooter?.social_links_details.map((stickyLink, index) => (
              <Link
                rel="noopener"
                aria-label="Facebook"
                to={index == 5 ? "/" : stickyLink?.social_links?.url}
                target="_blank"
                class="social-icon fb"
              >
                <img
                  src={stickyLink?.socialicon}
                  alt={stickyLink?.socialicon}
                />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Rightside;
