import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Aboutsectionone from "./aboutpage/Aboutsectionone";
import Aboutsectiontwo from "./aboutpage/Aboutsectiontwo";
import Aboutsectionthree from "./aboutpage/Aboutsectionthree";
import Aboutsectionfour from "./aboutpage/Aboutsectionfour";
import Aboutsectionfive from "./aboutpage/Aboutsectionfive";
import Aboutsectionsix from "./aboutpage/Aboutsectionsix";
import Aboutsectionseven from "./aboutpage/Aboutsectionseven";
import Aboutsectioneight from "./aboutpage/Aboutsectioneight";
import Loader from "./Loader";
import "../src/About.css";
import {
  apiCall,
  imageUpdate
} from "./authentication";
import Imgloader from "./componentsheaderfooter/Imgloader";
import Conloader from "./componentsheaderfooter/Conloader";

const About = () => {
  const [users2, setUsers2] = useState([]);
  const [aboutsection1, setAboutsection1] = useState([]);
  const [aboutsection1inner, setAboutsection1inner] = useState([]);
  const [aboutsection2, setAboutsection2] = useState([]);
  const [aboutsection3, setAboutsection3] = useState([]);
  const [aboutsection4, setAboutsection4] = useState([]);
  const [aboutsection5, setAboutsection5] = useState([]);
  const [aboutsection6, setAboutsection6] = useState([]);
  const [aboutsection7, setAboutsection7] = useState([]);
  const [aboutsection8, setAboutsection8] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [yoastData, setYoastData] = useState({});
  const slug = "wp/v2/pages?slug=about-us&acf_format=standard";
  const type = "get";
  let finalreacturl;
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug, type);
        setUsers2(data);
        setAboutsection1(data[0].acf.common_section[0]);
        setAboutsection1inner(data[0]);
        setAboutsection2(data[0].acf.about_us_section);
        setAboutsection3(data[0].acf.about_us_section[1]);
        setAboutsection4(data[0].acf.about_us_section[2]);
        setAboutsection5(data[0].acf.about_us_section[3]);
        setAboutsection6(data[0].acf.about_us_section[4]);
        setAboutsection7(data[0].acf.about_us_section[5]);
        setAboutsection8(data[0].acf.common_section[2]);
        setYoastData(data[0].yoast_head_json);

        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout == "banner_section") {
            setAboutsection1(element);
          }
          if (element.acf_fc_layout === "statics_section") {
            setAboutsection4(element);
          }
        });

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Empty dependency array ensures the effect runs once
  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }
  setTimeout(() => {
    imageUpdate();
  }, 1000);
  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <Aboutsectionone
              about1data={aboutsection1}
              about1datainner={aboutsection1inner}
            />
            <Aboutsectiontwo about2data={aboutsection2} />
            <Aboutsectionthree about3data={aboutsection3} />
            <Aboutsectionfour aboout4data={aboutsection4} />
            <Aboutsectionfive about5data={aboutsection5} />
            <Aboutsectionsix about6data={aboutsection6} />
            <Aboutsectionseven about7data={aboutsection7} />
            <Aboutsectioneight about8data={aboutsection8} />
          </div>
        )}
      </div>
    </>
  );
};

export default About;
