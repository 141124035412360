import hi from "date-fns/esm/locale/hi/index.js";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnerten(props) {
  const { hire10inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const triggerElements = [".hireanimation10", ".hireanimation101"];

    triggerElements.forEach((triggerElement, index) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: 0.8, // Adjust this value based on when you want the animation to start
        duration: "50%",
        reverse: true,
      });

      var animationTimeline = new TimelineMax();
      animationTimeline.to(
        $(".hireinneranimation10" + (index + 1)),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

      // Set the timeline as the tween for the ScrollMagic scene
      scene.setTween(animationTimeline);
      // Add the scene to the controller
      scene.addTo(controller);
    });

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire10inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire10inner.section_setting])

  return (
    <>
      {hire10inner.section_setting && hire10inner.section_setting === true ? (
        <section className="top-talents-hire-section step-hire-expert-section section-spacing pt-0">
          <div className="container hireanimation10">
            <div className="title-box hireinneranimation101">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="pe-5">
                    {hire10inner.step_to_hire_expert_title}
                  </h2>
                </>
              )}
            </div>
            <div className="d-flex all-developer hireanimation101 hireinneranimation102">
              {hire10inner?.step_to_hire_expert_lists &&
                hire10inner.step_to_hire_expert_lists.map((res, index) => {
                  return (
                    <div className="box" key={index}>
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <h5>{res.list_title}</h5>
                          <p
                            dangerouslySetInnerHTML={{ __html: res.list_content }}
                          ></p>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
            {isLoading ? (
              <Conloader />
            ) : (
              <>
                <Link
                  to={hire10inner.step_to_hire_button.url}
                  className="comman-btn top-space"
                  title={hire10inner.step_to_hire_button.title}
                  target={hire10inner.step_to_hire_button.target}
                  data-bs-toggle="modal"
                  data-bs-target="#FormModal"
                  onClick={props.handleClose}
                >
                  {hire10inner.step_to_hire_button.title}
                </Link>
              </>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnerten;
