import React, { useEffect, useState } from "react";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const PortfolioInnerTwo = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (post.acf.case_studies_detail_sections[1].section_settings === true) {
      setIsLoading(false);
    }
  }, [post.acf.case_studies_detail_sections[1].section_settings]);

  return (
    <>
      {post &&
        post.acf &&
        post.acf.case_studies_detail_sections &&
        post.acf.case_studies_detail_sections.length > 0 &&
        post.acf.case_studies_detail_sections[1].section_settings === true && (
          <section className="problem-section mt-4 pt-4 mb-4 pb-4">
            <div className="container">
              <div className="row">
                <div className="col-md-6 ">
                  {isLoading ? (
                    <>
                      <Conloader />
                      <Conloader />
                    </>
                  ) : (
                    <>
                      <div className="problem-text mt-3 mt-md-5 pt-3 pt-md-5 ">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[1]
                                ?.case_study_solution_left_section[0]
                                ?.case_study_left_section_title,
                          }}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[1]
                                ?.case_study_solution_left_section[0]
                                ?.case_study_left_section_content,
                          }}
                        />
                      </div>
                      <div className="vacuum-line mt-3 mt-md-5"></div>
                      <div className="problem-text mt-3 mt-md-5 pt-3 pt-md-4">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[1]
                                ?.case_study_solution_left_section[1]
                                ?.case_study_left_section_title,
                          }}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[1]
                                ?.case_study_solution_left_section[1]
                                ?.case_study_left_section_content,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-6 mt-3 mt-md-5">
                  {isLoading ? (
                    <>
                      <Imgloader />
                    </>
                  ) : (
                    <>
                      <div className="solution-text">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[1]
                                ?.case_study_solution_right_section[0]
                                ?.case_study_right_section_title,
                          }}
                        />
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              post?.acf?.case_studies_detail_sections[1]
                                ?.case_study_solution_right_section[0]
                                ?.case_study_right_section_content,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  );
};

export default PortfolioInnerTwo;
