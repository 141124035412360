import React, { useState, useEffect } from "react";
import Button from "../button/button";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

function Hiresectioneight({ hire8data }) {
  const [allArticleData, setAllArticleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let selectedIds = [];
  if (hire8data && Array.isArray(hire8data.select_articles)) {
    selectedIds = hire8data?.select_articles?.map((hire8data) => hire8data.ID);
  }
  const stringIds = selectedIds.toString();
  const slug1 = `wp/v2/articles?include=${stringIds}&_fields=id,title,_links,slug,date,_embedded.wp:featuredmedia,_embedded.wp:term&_embed`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug1, type);
        setAllArticleData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data from initial API:", error);
      }
    };

    fetchData();
  }, [stringIds]);

  return (
    <>
      <div>
        <div>
          {hire8data?.section_setting === true &&
            hire8data?.select_articles.length > 0 && (
              <div className="section-spacing hireblogsection">
                <div className="container">
                  <div className="row justify-content-between">
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <>
                        <h2 className="col-md-6 col-12 page-title fw-bold mb-md-5 mb-3">
                          {hire8data?.article_main_title} @123
                        </h2>
                        <Button
                          classadd="col-md-6 col-12 text-md-end mb-md-0 mb-5"
                          name={hire8data?.article_button_name?.title}
                          link={hire8data?.article_button_name?.url}
                          target={hire8data?.article_button_name?.target}
                        />
                      </>
                    )}
                  </div>
                  <div className="row justify-content-start">
                    {allArticleData?.map((innerArticle, index) => {
                      return (
                        <div className="artical-card dot-animation">
                          {isLoading ? (
                            <Imgloader />
                          ) : (
                            <>
                              <Link to={`/articles/${innerArticle.slug}`}>
                                <div className="artical-card-img">
                                  <img
                                    src={
                                      innerArticle?._embedded[
                                        "wp:featuredmedia"
                                      ]?.[0]?.source_url
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="row justify-content-between align-items-center artical-card-head">
                                  <div className="col">
                                    <p>
                                      {/* Web Development */}
                                      {
                                        innerArticle?._embedded[
                                          "wp:term"
                                        ]?.[0]?.[0]?.name
                                      }
                                    </p>
                                  </div>
                                  <div className="col">
                                    <div className="d-flex justify-content-end dot-flex-testi">
                                      <div className="dot-testi light-green-testi"></div>
                                      <div className="dot-testi green-testi"></div>
                                      <div className="dot-testi blue-testi"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="artical-card-title">
                                  <h3>
                                    {/* Cost to Develop a Website in 2023: The Complete Guide */}
                                    {innerArticle?.title?.rendered}
                                  </h3>
                                  <p className="artical-card-date">
                                    {/* July 26, 2023 */}
                                    {formatDate(innerArticle?.date)}
                                  </p>
                                </div>
                              </Link>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(inputDate);
  return date.toLocaleDateString("en-US", options);
}

export default Hiresectioneight;
