import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Servicesectionthree = ({ services3data }) => {
  const imageRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".servicepageprocess",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    // Intersection Observer to load image once it appears on the screen
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    function handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Load the image when it becomes visible
          observer.unobserve(entry.target);
          gsap.to(entry.target, {
            duration: 0.5,
            opacity: 1,
            ease: Power0.easeNone,
          });
        }
      });
    }

    observer.observe(imageRef.current);

    if (services3data.section_setting === true) {
      setIsLoading(false);
    }

    return () => {
      controller.destroy();
      observer.disconnect();
    };
  }, []);
  return (
    <>
      <div className="section-spacing graybg servicepageprocess">
        <div className="container">
          <div className="row commonanimation">
            <div className="col-12 mb-5">
              {isLoading ? (
                <Conloader />
              ) : (
                <h2 className="page-title fw-bold text-center mb-2">
                  {services3data?.why_hire_developer_main_title}
                </h2>
              )}
            </div>
            <div className="col-12">
              <img
                className="doodles-image"
                ref={imageRef}
                src={services3data?.service_animated_image?.url}
                alt={services3data?.service_animated_image?.alt}
                style={{ opacity: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Servicesectionthree;
