import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

const ServiceDetailoseven = ({ inner }) => {
  const [hire11inner, setHire11Inner] = useState(inner);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail9section",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".servicesdanimation9"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);
    scene.setClassToggle(".servicesdetail9section", "title-colorchange");

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setHire11Inner(inner);
    if (hire11inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [inner]); // Run this effect whenever 'inner' prop changes

  const handleLinkClick = () => {
    // Assuming tidioChatApi is globally accessible
    if (window.tidioChatApi) {
      window.tidioChatApi.display(true);
      window.tidioChatApi.open();
    }
  };

  return (
    <>
      {hire11inner?.section_setting && hire11inner.section_setting === true ? (
        <section id="faq" className="section-spacing servicesdetail9section">
          <div className="container">
            <div className="row mx-0 p-0 justify-content-between faq_head servicesdanimation9">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <div className="col-md-4 p-0">
                    {/* <h2>Frequently Asked Questions</h2> */}
                    <h2>{hire11inner.faq_title}</h2>
                  </div>
                  <div className="col-md-7 p-0">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hire11inner.faq_description,
                      }}
                    ></p>
                  </div>
                </>
              )}
            </div>
            <div className="row mx-0 p-0 justify-content-between faq_acc servicesdanimation9">
              {isLoading ? (
                <div className="row">
                  <div className="col-md-6 col-12">
                    <Conloader />
                  </div>
                  <div className="col-md-6 col-12">
                    <Conloader />
                    <Conloader />
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-md-3 p-0 faq_acc_head">
                    {/* <p>Still have Questions?</p> */}
                    <p onClick={handleLinkClick}>{hire11inner.faq_sub_title}</p>
                  </div>
                  <div className="col-md-9 p-0">
                    <div className="accordion" id="accordionFirstExample">
                      {hire11inner.faq_list &&
                        hire11inner.faq_list.map((response, index) => {
                          return (
                            <>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id={`heading${index}`}
                                >
                                  <button
                                    className={`accordion-button ${index === 0 ? "" : "collapsed"
                                      }`}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={index === 0 ? "true" : "false"}
                                    aria-controls={`collapse${index}`}
                                  >
                                    {response.post_title}
                                  </button>
                                </h2>
                                <div
                                  id={`collapse${index}`}
                                  className={`accordion-collapse ${index === 0 ? "collapse show" : "collapse"
                                    }`}
                                  aria-labelledby={`headinguiux${index}`}
                                  data-bs-parent="#accordionFirstExample"
                                >
                                  <div className="accordion-body">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: response.post_content,
                                      }}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ServiceDetailoseven;
