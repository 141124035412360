import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const Hiresectiontwo = (props) => {
  const { hire2data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".top-mobile-development-section",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    if (hire2data.section_setting === true) {
      setIsLoading(false);
    }

    return () => {
      controller.destroy();
    };
  }, [hire2data.section_setting]);

  return (
    <>
      {hire2data.section_setting === true ? (
        <section className="top-mobile-development-section section-spacing">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="img-box commonanimation">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <>

                    <img
                      src={hire2data.txt_img_sec_image.url}
                      alt={hire2data.txt_img_sec_image.alt}
                    />
                  </>
                )}
              </div>
              <div className="content-box commonanimation">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="title-box">
                      <h2>{hire2data.txt_img_sec_title}</h2>
                    </div>
                    <p>
                      <b>{hire2data.txt_img_sec_sub_title}</b>
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hire2data.txt_img_sec_description,
                      }}
                    ></p>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Hiresectiontwo;
