import React, { useEffect, useState } from 'react';
import Conloader from '../componentsheaderfooter/Conloader';

const PortfolioInnerTwo = ({ post }) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (post.acf.projectportfolio_detail_sections[1].section_settings === true) {
            setIsLoading(false);
        }
    }, [post.acf.projectportfolio_detail_sections[1].section_settings]);
    return (
        <>
            {post &&
                post.acf &&
                post.acf.projectportfolio_detail_sections &&
                post.acf.projectportfolio_detail_sections.length > 0 &&
                post.acf.projectportfolio_detail_sections[1].section_settings === true && (
                    <section className="problem-section mt-4 pt-4 mb-4 pb-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 ">
                                    {isLoading ? (
                                        <>
                                            <Conloader />
                                            <Conloader />
                                        </>
                                    ) : (
                                        <>
                                            <div className="problem-text mt-3 mt-md-5 pt-3 pt-md-5 ">
                                                <h3>{post?.acf?.projectportfolio_detail_sections[1]?.portfolio_solution_left_section[0]?.solution_left_section_title}</h3>
                                                <p dangerouslySetInnerHTML={{ __html: post?.acf?.projectportfolio_detail_sections[1]?.portfolio_solution_left_section[0]?.solution_left_section_content }}></p>
                                            </div>
                                            <div className="vacuum-line mt-3 mt-md-5"></div>
                                            <div className="problem-text mt-3 mt-md-5 pt-3 pt-md-4">
                                                <h3>{post?.acf?.projectportfolio_detail_sections[1]?.portfolio_solution_left_section[1]?.solution_left_section_title}</h3>
                                                <p dangerouslySetInnerHTML={{ __html: post?.acf?.projectportfolio_detail_sections[1]?.portfolio_solution_left_section[1]?.solution_left_section_content }}></p>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="col-md-6 mt-3 mt-md-5">
                                    {isLoading ? (
                                        <>
                                            <Conloader />
                                            <Conloader />
                                        </>
                                    ) : (
                                        <div className="solution-text">
                                            <h3>{post?.acf?.projectportfolio_detail_sections[1]?.portfolio_solution_right_section[0]?.solution_right_section_title}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: post?.acf?.projectportfolio_detail_sections[1]?.portfolio_solution_right_section[0]?.solution_right_section_content }}></p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
        </>
    )
}

export default PortfolioInnerTwo;