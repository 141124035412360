import React, { useEffect } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

const Homesectionfive = (props) => {
  const { section5data } = props;
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".industrysecion",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation1"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);
  return (
    <>
      {section5data.section_setting === true ? (
        <div className="section-spacing pt-0 industrysecion">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 mb-md-0 mb-3">
                <h2 className="page-title fw-bold  mb-2 commonanimation1">
                  {section5data.txt_img_sec_title}
                </h2>
                <div className="sub-title fw-600 mb-4 commonanimation1">
                  {section5data.txt_img_sec_sub_title}
                </div>
                <div
                  className="commonanimation1"
                  dangerouslySetInnerHTML={{
                    __html: section5data.txt_img_sec_description,
                  }}
                />
              </div>
              <div className="col-12 col-md-6">
                <img
                  src={section5data.txt_img_sec_image.url}
                  className="img-fluid"
                  alt={section5data.txt_img_sec_image.title}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectionfive;
