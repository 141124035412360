import React, { useEffect, useState } from "react";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { Linear } from "gsap/all";
import author from "../img/homeimg/mr-harvey.png";
import project from "../img/homeimg/testimonial-img-1.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../Loader";
import image from "../img/placeholder-1-1.webp";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";
const Servicesectionsix = (props) => {
  const [articaleData, setarticaleData] = useState([]);
  const [section8data, setsection8data] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const testimonialslider = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    cssEase: "ease",
    adaptiveHeight: true,
  };
  let selectedIds = [];
  let stringIds = "";
  if (props.inner && Array.isArray(props.inner.select_testmonials)) {
    selectedIds = props.inner.select_testmonials?.map(
      (section9data) => section9data
    );
    stringIds = selectedIds.toString();
  }
  const slug = `wp/v2/testimonial?_embed&include=${stringIds}`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug, type);
        setsection8data(data);
        setarticaleData(props.inner);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props]);

  return (
    <>
      <div>

        <div>
          {articaleData && articaleData.section_settings === true ? (
            <div className="testimonial-section">
              <div className="section-spacing graybg pb-0">
                <div className="container">
                  <div className="row">
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <div className="col-12 mb-3">
                        <h2 className="page-title fw-bold">
                          {articaleData?.testimonials_title}
                        </h2>
                        <div className="sub-title fw-600">
                          {articaleData?.testimonials_sub_title}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="section-spacing graybg pt-5">
                <Slider id="protfolioslider" {...testimonialslider}>
                  {section8data &&
                    section8data?.map((response, index) => {
                      return (
                        <>
                          <div
                            className="slick-slider dot-animation"
                            key={index}
                          >
                            <div className="container">
                              <div className="row">
                                <div className="col-md-8 col-lg-7 col-12 gx-lg-5 mb-md-0 mb-4">
                                  {isLoading ? (
                                    <Conloader />
                                  ) : (
                                    <>
                                      <div className="d-flex dot-flex-testi">
                                        <div className="dot-testi light-green-testi"></div>
                                        <div className="dot-testi green-testi"></div>
                                        <div className="dot-testi blue-testi"></div>
                                      </div>
                                      <div className="testimonialauthor mt-4">
                                        <div className="sub-title fw-bold mb-2">
                                          {response.project_data?.project_name}
                                        </div>
                                        {/* <p>{response.yoast_head_json?.og_description}</p> */}
                                      </div>
                                      <div className="testimonialauthourwithimage mt-md-4 mt-2 d-flex align-items-center">
                                        <div className="testi-img">
                                          {response?._embedded &&
                                            response?._embedded[
                                            "wp:featuredmedia"
                                            ] &&
                                            response?._embedded[
                                              "wp:featuredmedia"
                                            ][0].source_url ? (
                                            <img
                                              src={
                                                response._embedded?.[
                                                  "wp:featuredmedia"
                                                ]?.[0]?.source_url
                                              }
                                              alt={
                                                response._embedded?.[
                                                  "wp:featuredmedia"
                                                ]?.[0]?.alt_text
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={image}
                                              alt="placeholderimage"
                                            />
                                          )}
                                        </div>
                                        <div className="testiname">
                                          <div className="sub-title mb-0 fw-bold">
                                            {response.title?.rendered}
                                          </div>
                                          <div className="fw-bold f14">
                                            {response.acf?.designation}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-md-4 mt-2 testimonialcontent">
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: response?.content?.rendered,
                                          }}
                                        ></p>
                                      </div>
                                      <b
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            response.acf
                                              ?.testimonial_youtube_title,
                                        }}
                                      ></b>
                                    </>
                                  )}
                                </div>

                                <div className="col-md-4 col-lg-5 col-12 text-md-end text-center">
                                  {isLoading ? (
                                    <Imgloader />
                                  ) : (
                                    <>
                                      {response?.project_data &&
                                        response.project_data.project_image &&
                                        response.project_data.project_image.url ? (
                                        <img
                                          src={
                                            response.project_data?.project_image
                                              ?.url
                                          }
                                          className="img-fluid project-img"
                                          alt={
                                            response.project_data?.project_name
                                          }
                                        />
                                      ) : (
                                        <img src={image} alt="placeholderimage" />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </Slider>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Servicesectionsix;
