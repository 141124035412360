import React, { useEffect, useState } from "react";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const PortfolioInnerOne = ({ post }) => {
  const [isLoading, setIsLoading] = useState(true);
  let workIndex = localStorage.getItem("work index");
  // console.log(workIndex);
  if (!workIndex) {
    workIndex = "0";
  }

  useEffect(() => {
    if (post.acf.projectportfolio_detail_sections[0].section_settings === true) {
      setIsLoading(false);
    }
  }, [post.acf.projectportfolio_detail_sections[0].section_settings]);

  return (
    <>
      {post &&
        post.acf &&
        post.acf.projectportfolio_detail_sections &&
        post.acf.projectportfolio_detail_sections.length > 0 &&
        post.acf.projectportfolio_detail_sections[0].section_settings ===
        true && (
          <section
            className={`thinkvacuum-section pt-5 mt-5 pt-3 home-banner pn-color${workIndex}`}
          >
            <div className="container pt-5">
              <div className="row vacuum-container mt-5 pt-5">
                <div className="col-md-6">
                  {isLoading ? (
                    <Imgloader />
                  ) : (
                    <div className="vacuum-inner-text p-3 p-md-5 ">
                      <h2>
                        {
                          post?.acf?.projectportfolio_detail_sections[0]
                            ?.portfolio_inner_project_title
                        }
                      </h2>
                      {post?.yoast_head_json &&
                        post?.yoast_head_json?.og_image && (
                          <img
                            src={
                              post?.acf?.projectportfolio_detail_sections[0]
                                ?.portfolio_inner_project_image?.url
                            }
                            className="img-fluid"
                            alt={
                              post?.acf?.projectportfolio_detail_sections[0]
                                ?.portfolio_inner_project_title
                            }
                          />
                        )}
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <div className="vacuum-content p-3 p-md-5 ">
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            post?.acf?.projectportfolio_detail_sections[0]
                              ?.portfolio_inner_project_content,
                        }}
                      ></p>
                      <div className="d-flex mt-xxl-5 mt-md-4 mt-1 vacuum-project justify-content-between">
                        <div className="project-text">
                          <h3>
                            {
                              post?.acf?.projectportfolio_detail_sections[0]
                                ?.portfolio_inner_project_details[0]
                                ?.project_inner_title
                            }
                          </h3>
                          <p>
                            {
                              post?.acf?.projectportfolio_detail_sections[0]
                                ?.portfolio_inner_project_details[0]
                                ?.project_inner_description
                            }
                          </p>
                        </div>
                        <div className="project-text">
                          <h3>
                            {
                              post?.acf?.projectportfolio_detail_sections[0]
                                ?.portfolio_inner_project_details[1]
                                ?.project_inner_title
                            }
                          </h3>
                          <p>
                            {
                              post?.acf?.projectportfolio_detail_sections[0]
                                ?.portfolio_inner_project_details[1]
                                ?.project_inner_description
                            }
                          </p>
                        </div>
                      </div>
                      <div className="project-text mt-xxl-4 mt-md-2 mt-1">
                        <h3>
                          {
                            post?.acf?.projectportfolio_detail_sections[0]
                              ?.portfolio_inner_project_details[2]
                              ?.project_inner_title
                          }
                        </h3>
                        <p>
                          {
                            post?.acf?.projectportfolio_detail_sections[0]
                              ?.portfolio_inner_project_details[2]
                              ?.project_inner_description
                          }
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
    </>
  );
};

export default PortfolioInnerOne;
