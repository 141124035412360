import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const CounterItem = ({ counterData, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleVisibilityChange = (visible) => {
    if (visible) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    if (isVisible === true) {
      setIsLoading(false);
    }
  }
  );

  return (
    <VisibilitySensor partialVisibility onChange={handleVisibilityChange}>
      <div className="col-lg-4 col-md-6 col-12">
        {isLoading ? (
          <Conloader />
        ) : (
          <div className="counter-main mb-lg-0 mb-5 justify-content-lg-center">
            <div className={`counter-icon ${"counter-icon" + index}`}>
              <img
                src={counterData.counter_icon.url}
                className="img-fluid"
                alt={counterData.counter_icon.title}
              />
            </div>
            <div className="counter-content-main">
              <div className="counter-content mb-md-3 mb-1">
                {isVisible && (
                  <span>
                    <CountUp start={0} end={counterData.numbers} duration={4} />+
                  </span>
                )}
              </div>
              <div className="sub-title fw-600">{counterData.counter_text}</div>
            </div>
          </div>
        )}
      </div>
    </VisibilitySensor>
  );
};

const Homesectionfour = (props) => {
  const { section4data } = props;
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    setIsLoading(false);
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    var controllerMid = new ScrollMagic.Controller();

    const scene1 = new ScrollMagic.Scene({
      triggerElement: ".mapTriggerStart",
      triggerHook: 0.5,
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    scene1.setTween(animationTimeline);
    scene1.addTo(controllerMid);
    scene1.setClassToggle(".counter-icon", "counter-icon-animate");

    return () => {
      controllerMid.destroy();
    };
  }, [section4data]);

  // useEffect(() => {
  //   if (section4data?.section_settings === true) {
  //     setIsLoading(false);
  //   }
  // }
  // );

  return (
    <>
      {section4data.section_setting === true ? (
        <div className="section-spacing pt-0 mapanimationtext commonanimation">
          <div className="mapTriggerStart"></div>
          <div className="container">

            <div className="row">
              {isLoading ? (
                <Conloader />
              ) : (
                <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 text-center">
                  <h2 className="page-title fw-bold fw-bold mb-md-4 mb-3">
                    {section4data.big_title}
                  </h2>
                  <div className="sub-title fw-bold">
                    {section4data.sub_text}
                  </div>
                </div>
              )}
            </div>
            <div className="row pt-5 pb-5">
              <div className="col-12">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <img
                    src={section4data.map_image.url}
                    className="img-fluid"
                    alt="Map"
                  />
                )}
              </div>
            </div>

            <div className="row justify-content-lg-between justify-content-center mt-md-5">
              {section4data.counter &&
                section4data.counter.length > 0 &&
                section4data.counter.map((key, index) => (
                  <CounterItem key={index} counterData={key} index={index} />
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectionfour;
