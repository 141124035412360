import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { apiCall } from "../authentication";
import { Spinner } from "../Spinner";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Homesectiontwo = (props) => {
  const { section2data } = props;
  const [partnerdata, setPartnerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const logosetting = {
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    cssEase: "ease",
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  let selectedIds = [];

  if (
    section2data.partners_logo &&
    Array.isArray(section2data.partners_logo) &&
    section2data.partners_logo.length > 0
  ) {
    selectedIds = section2data.partners_logo
      .slice(0, 10)
      .map((section9data) => section9data.ID);
  } else {
  }
  const stringIds = selectedIds.toString();
  const slug = `homepartners/hpartners/${stringIds}`;
  const type = "get";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await apiCall(slug, type);
        setPartnerData(result);
        setIsLoading(false);
        // Handle the result as needed
      } catch (error) {
        // Handle API error
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0, // Adjust the threshold as needed
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      // Cleanup the observer when the component is unmounted
      observer.disconnect();
    };
  }, [sectionRef]);

  return (
    <>
      {section2data.section_settings === true ? (
        <>
          <div ref={sectionRef} className={`section-spacing graybg px-3`}>
            <div className="container">
              {isLoading ? (
                <div className="my-5">
                  <Conloader />
                </div>
              ) : (
                <>
                  <h2
                    className={`page-title fw-bold text-center ${isVisible ? "visibleelement" : ""
                      }`}
                  >
                    {section2data.our_partner_text}
                  </h2>
                </>
              )}
            </div>

            {isLoading ? (
              <div className="my-5">
                <Imgloader />
              </div>
            ) : (
              <>
                <Slider
                  className="logo-slider logo-slider-home mt-5"
                  {...logosetting}
                >
                  {partnerdata &&
                    partnerdata.length > 0 &&
                    partnerdata?.map((key1, index) => (
                      <div key={index} className="position-relative item">
                        <img
                          className="img-fluid"
                          src={key1?.featured_image?.src}
                          alt={key1?.featured_image?.alt}
                        />
                      </div>
                    ))}
                </Slider>
                <div className="lionAnimTriggerStart"></div>
              </>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectiontwo;
