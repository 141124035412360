import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Aboutsectionfour = (props) => {
  const { aboout4data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const oddIndexData = aboout4data.statics_details.filter(
    (item, index) => index % 2 === 0
  );
  const evenIndexData = aboout4data.statics_details.filter(
    (item, index) => index % 2 !== 0
  );

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);
    const upscrollposition = $(window).width() - $(".slide-up").width();
    const downscrollposition = $(".slide-down").width() - $(window).width();
    $(".slide-down").css("transform", `translateX(${-downscrollposition}px)`);
    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".slide-up",
      triggerHook: 0.7, // Adjust this value based on when you want the animation to start
      duration: "50%", // Length of the animation trigger
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline
      .to(
        $(".slide-up"),
        {
          x: upscrollposition, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      )
      .to(
        $(".slide-down"),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {

    if (aboout4data.section_settings === true) {
      setIsLoading(false);
    }
  }, [aboout4data.section_settings]);

  return (
    <>
      <div className="section-spacing atlasdark overflow-hidden">
        <div className="scroll-slide-number">
          {isLoading ? (
            <div className="container">
              <Imgloader />
            </div>
          ) : (
            <>
              <div className="slide slide-up">
                {oddIndexData.map((item, index) => (
                  <div key={index} className="main-slide-number">
                    <span className="number">{item.statics_number}</span>
                    <span className="text">{item.statics_content}</span>
                  </div>
                ))}
              </div>
              <div className="slide slide-down">
                {evenIndexData.map((item, index) => (
                  <div key={index} className="main-slide-number">
                    <span className="number">{item.statics_number}</span>
                    <span className="text">{item.statics_content}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Aboutsectionfour;
