import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnerseven(props) {
  const { hire7inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const triggerElements = [".hireanimation7", ".hireanimation71"];

    triggerElements.forEach((triggerElement, index) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: 0.8, // Adjust this value based on when you want the animation to start
        duration: "50%",
        reverse: true,
      });

      var animationTimeline = new TimelineMax();
      animationTimeline.to(
        $(".hireinneranimation7" + (index + 1)),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

      // Set the timeline as the tween for the ScrollMagic scene
      scene.setTween(animationTimeline);
      // Add the scene to the controller
      scene.addTo(controller);
    });

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire7inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire7inner.section_setting]);
  return (
    <>
      {hire7inner.section_setting && hire7inner.section_setting === true ? (
        <section className="skills-developer-section section-spacing">
          <div className="container">
            <div className="title-box d-flex hireanimation7 hireinneranimation71">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="pe-5">
                    {hire7inner.skills_title}
                  </h2>
                  <p
                    // className="hireinneranimation71"
                    dangerouslySetInnerHTML={{
                      __html: hire7inner.skill_description,
                    }}
                  ></p>
                </>
              )}
            </div>
            <div className="technical-non-technical hireanimation71">
              {hire7inner.skills_list &&
                hire7inner.skills_list.map((res, index) => {
                  return (
                    <>
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <div
                            className={
                              index === 1
                                ? "dark box"
                                : "box"
                            }
                          >
                            <div className="title-box">
                              <h2>{res.list_title}</h2>
                            </div>
                            {/* <h6>{res.list_sub_title}</h6> */}
                            <ul>
                              {res.list_desc.map((Response, key) => {
                                return <li>{Response.list}</li>;
                              })}
                            </ul>
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
            </div>
            {isLoading ? (
              <Conloader />
            ) : (
              <Link
                to={hire7inner.skills_button_link.url}
                target={hire7inner.skills_button_link.target}
                className="comman-btn top-space"
                title={hire7inner.skills_button_link.title}
                data-bs-toggle="modal"
                data-bs-target="#FormModal"
                onClick={props.handleClose}
              >
                {hire7inner.skills_button_link.title}
              </Link>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnerseven;
