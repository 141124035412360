import React, { useEffect, useState } from 'react';
import Conloader from '../componentsheaderfooter/Conloader';

function BrandOne(props) {
    const { brands1data } = props;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (brands1data.section_settings === true) {
            setIsLoading(false);
        }
    }, [brands1data.section_settings]);

    return (
        <>
            {brands1data.section_settings === true ? (
                <div className="pt-5 pb-lg-5">
                    <div className="container pt-5 pb-5">
                        <div className="row gx-md-5 pt-5 pb-lg-5 align-items-center">
                            <div className="col-md-7 col-12">
                                {isLoading ? (
                                    <Conloader />
                                ) : (
                                    <>
                                        <p className="list-link">{brands1data.banner_title}</p>
                                        <h2 className="fw-bold page-title">{brands1data.banner_sub_title}</h2>
                                        <p className="since-title" dangerouslySetInnerHTML={{ __html: brands1data.banner_content }}></p>
                                        <div className="atlas-paragraph"></div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    )
}

export default BrandOne