import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Homesectionseven = (props) => {
  const { section7data } = props;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);
    setIsLoading(false);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".homeabout2section",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation2"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <>
      {section7data.section_setting === true ? (
        <div className="section-spacing homeabout2section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6 mb-lg-0 mb-3 commonanimation2">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <h2 className="page-title fw-bold  mb-3 mb-md-5">
                      {section7data.txt_img_sec_title}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section7data.txt_img_sec_description,
                      }}
                    />
                  </>
                )}
              </div>
              <div className="col-12 col-lg-6 commonanimation2">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <img
                    src={section7data.txt_img_sec_image.url}
                    className="img-fluid"
                    alt={section7data.txt_img_sec_image.title}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectionseven;
