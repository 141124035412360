import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Servicesectionone from "./Service/Servicesectionone";
import Servicesectiontwo from "./Service/Servicesectiontwo";
import Servicesectionthree from "./Service/Servicesectionthree";
import Servicesectionfour from "./Service/Servicesectionfour";
import Servicesectionfive from "./Service/Servicesectionfive";
import Servicesectionsix from "./Service/Servicesectionsix";
import Servicesectionseven from "./Service/Servicesectionseven";
import CommonCta from "./CommonCta";
import "../src/Service.css";
import { apiCall, imageUpdate } from "./authentication";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const Service = () => {
  const [users3, setUsers3] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [servicessection1, setServicessection1] = useState([]);
  const [servicessection2, setServicessection2] = useState([]);
  const [servicessection3, setServicessection3] = useState([]);
  const [servicessection4, setServicessection4] = useState([]);
  const [servicessection5, setServicessection5] = useState([]);
  const [servicessection6, setServicessection6] = useState([]);
  const [servicessection7, setServicessection7] = useState([]);
  const [yoastData, setYoastData] = useState({});

  const slug = "wp/v2/pages?slug=our-services";
  const type = "get";
  let finalreacturl;
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug, type);
        setUsers3(data);
        setYoastData(data[0].yoast_head_json);
        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout == "banner_section") {
            setServicessection1(element);
          }

          if (element.acf_fc_layout == "article_section") {
            setServicessection7(element);
          }

          if (element.acf_fc_layout == "testimonials_section") {
            setServicessection6(element);
          }
        });

        data[0].acf.our_service_section.forEach((element) => {
          if (element.acf_fc_layout == "service_list_section") {
            setServicessection2(element);
          }

          if (element.acf_fc_layout == "why_hire_developer_section") {
            setServicessection3(element);
          }

          if (element.acf_fc_layout == "service_tab_section") {
            setServicessection4(element);
          }

          if (element.acf_fc_layout == "benefits_section") {
            setServicessection5(element);
          }
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }
  setTimeout(() => {
    imageUpdate();
  }, 1000);
  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <Servicesectionone services1data={servicessection1} />
            <Servicesectiontwo inner={servicessection2} />
            <Servicesectionthree services3data={servicessection3} />
            <Servicesectionfour services4data={servicessection4} />
            <Servicesectionfive services5data={servicessection5} />
            <Servicesectionsix inner={servicessection6} />
            <Servicesectionseven article={servicessection7} />
            <CommonCta />
          </div>
        )}
      </div>
    </>
  );
};

export default Service;
