import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

const ServicesDetailothree = ({ inner }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".servicesdetail3section",
      triggerHook: 0.6, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation2"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);
    scene.setClassToggle(".servicesdetail3section", "title-colorchange");

    if (inner?.section_setting === true) {
      setIsLoading(false);
    }

    return () => {
      controller.destroy();
    };
  }, []);
  return (
    <>
      {inner?.section_setting === true && (
        <section
          className="top-talents-hire-section section-spacing servicesdetail3section"
          id="service_inner_ui"
        >
          <div className="container">
            <div className="title-box d-flex justify-content-between commonanimation2">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="pe-5">{inner?.service_process_title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: inner?.service_process_description,
                    }}
                  />
                </>
              )}
            </div>
            <div className="d-flex justify-content-start flex-wrap all-developer service-detail commonanimation2">
              {inner &&
                inner !== null &&
                inner?.service_process_type?.map((detail, index) => (
                  <div className="box" key={index}>
                    {isLoading ? (
                      <Conloader />
                    ) : (
                      <>
                        <div>
                          <h5>{detail?.service_process_type_title}</h5>
                          <ul>
                            <li>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: detail?.service_process_type_desc,
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                        <div className="dots">
                          <span className="green"></span>
                          <span className="black"></span>
                          <span className="blue"></span>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ServicesDetailothree;
