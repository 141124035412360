import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Hiresectionfour = (props) => {
  const { hire4data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".mobile-services-section",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation2"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire4data.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire4data.section_setting])

  return (
    <>
      {hire4data.section_setting === true ? (
        <section className="mobile-services-section section-spacing">
          <div className="container">
            <div className="title-box commonanimation2">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2>{hire4data.mdevsev_section_title}</h2>
                </>
              )}
            </div>
            <div className="d-flex commonanimation2">
              <div className="content">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: hire4data.mdevsev_section_content,
                      }}
                    ></p>
                  </>
                )}
              </div>
              <div className="developer-icon">
                {hire4data.mdevsev_technologies &&
                  hire4data.mdevsev_technologies.map((response, index) => {
                    return (
                      <>
                        <figure>
                          {isLoading ? (
                            <Imgloader />
                          ) : (
                            <>
                              <Link to={`/hire${response.icon_link?.url}`}>
                                <img src={response.technology_icon} alt="php" />
                              </Link>
                            </>
                          )}
                        </figure>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Hiresectionfour;
