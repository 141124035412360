import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SlidDetail = ({ settings, data }) => {
  return (
    <Slider
      className="technologies_thumb slider-thumb text-white"
      {...settings}
    >
      {data &&
        data.length > 0 &&
        data.map((detail, key) => (
          <div className="choose_slide" key={key}>
            <div>
              <h3>{detail?.why_choose_type_title}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: detail?.why_choose_type_desc,
                }}
              ></p>
            </div>
            <div className="dots">
              <span className="green"></span>
              <span className="black"></span>
              <span className="blue"></span>
            </div>
          </div>
        ))}
    </Slider>
  );
};

export default SlidDetail;
