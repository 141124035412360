import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiCall } from "../authentication";
import Imgloader from "../componentsheaderfooter/Imgloader";

function Rating() {
  const [Reating1, setReating1] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const slug1 = "options/all?_fields=footer_section";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchRating = async () => {
      try {
        const data = await apiCall(slug1, type);
        setReating1(data.footer_section.company_review_details);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchRating();
    // Step 1: Fetch data from the initial REST API
  }, []);


  return (
    <div className="section-spacing atlasdark px-3 pb-0">
      <div className="container abouteight">
        <div className="row gap-4 m-0 justify-content-between align-items-stretch rate-card">
          {Reating1 &&
            Reating1.map((res, index) => {
              return (
                <Link
                  to={res.company_link.trim()}
                  target="_blank"
                  className="col-md-2 col-sm-5 col-12 d-flex align-items-center rate-card-inner"
                >
                  {isLoading ? (
                    <Imgloader />
                  ) : (
                    <Aboutrate
                      key={index}
                      img={res.company_logo.url}
                      imgalt={res.company_logo.url}
                      rate={res.company_rate}
                      iconalt={res.company_icon.url}
                      icon={res.company_icon.url}
                    />
                  )}
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function Aboutrate(props) {
  return (
    <>
      <figure className="rate-img me-4">
        <img src={props.img} alt={props.imgalt} />
      </figure>
      <div className="rate_point">
        <span className="rate-star">
          <img src={props.icon} alt={props.iconalt} />
        </span>
        <span>
          <p className="text-white">{props.rate}</p>
        </span>
      </div>
    </>
  );
}

export default Rating;
