import React from "react";
import InfographicsListone from "./infographicslistpage/InfographicsListone";
import CommonCta from "./CommonCta";

const InfographicsList = () => {
  return (
    <>
      <InfographicsListone />
      <CommonCta />
    </>
  );
};

export default InfographicsList;
