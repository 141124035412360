import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnernine(props) {
  const { hire9inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const triggerElements = [".hireanimation9", ".hireanimation91"];

    triggerElements.forEach((triggerElement, index) => {
      const scene = new ScrollMagic.Scene({
        triggerElement: triggerElement,
        triggerHook: 0.8, // Adjust this value based on when you want the animation to start
        duration: "50%",
        reverse: true,
      });

      var animationTimeline = new TimelineMax();
      animationTimeline.to(
        $(".hireinneranimation9" + (index + 1)),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      );

      // Set the timeline as the tween for the ScrollMagic scene
      scene.setTween(animationTimeline);
      // Add the scene to the controller
      scene.addTo(controller);
    });

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire9inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire9inner.section_setting])

  return (
    <>
      {hire9inner.section_setting && hire9inner.section_setting === true ? (
        <section className="why-us-section section-spacing">
          <section className="container hireanimation9">
            <div className="title-box hireinneranimation91">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <h2 className="">
                    {hire9inner.step_to_hire_}
                  </h2>
                </>
              )}
            </div>
            {isLoading ? (
              <Conloader />
            ) : (
              <>
                <h5 className="">
                  {hire9inner.step_to_hire_lists?.[0]?.list_title}
                </h5>
                <ul className="">
                  {hire9inner.step_to_hire_lists?.[0]?.list_content?.map(
                    // eslint-disable-next-line array-callback-return
                    (response, index) => {
                      return (
                        <>
                          <li className="">{response.list}</li>
                        </>
                      );
                    }
                  )}
                </ul>
              </>
            )}
          </section>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnernine;
