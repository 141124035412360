import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import designleft from "../img/homeimg/design-left.svg";
import designcenter from "../img/homeimg/design-center.svg";
import designright from "../img/homeimg/design-right.svg";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";
const Homesectionthree = (props) => {
  const { section3data } = props;

  const dot1Ref = useRef(null);
  const dot2Ref = useRef(null);
  const dot3Ref = useRef(null);

  const [dot1position, setDot1position] = useState();
  const [dot2position, setDot2position] = useState();
  const [dot3position, setDot3position] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setDot1position($(dot1Ref.current).offset().top);
    setDot2position($(dot2Ref.current).offset().top);
    setDot3position($(dot3Ref.current).offset().top);
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    if (section3data.section_setting === true) {
      setIsLoading(false);
    }

    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    var controllerMid = new ScrollMagic.Controller();

    // First ScrollMagic Scene
    const scene1 = new ScrollMagic.Scene({
      triggerElement: ".lionAnimTriggerStart", // Replace with your trigger element
      triggerHook: 0.6, // Trigger the animation when the element is in the middle of the viewport
      duration: "90%", // Length of the animation trigger
      reverse: true,
    });

    // Intersection Observer to add class to whoWeAreImg when it becomes visible
    const whoWeAreImgObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Element is visible, add your class to whoWeAreImg
            $(".whoWeAreImg").addClass("filledcircles"); // replace 'your-class-name' with the actual class name you want to add
          } else {
            $(".whoWeAreImg").removeClass("filledcircles");
          }
        });
      },
      { threshold: 1 } // Adjust the threshold as needed
    );

    // Observe the whoWeAreImg element
    whoWeAreImgObserver.observe(document.querySelector(".whoWeAreImg"));
    var animationTimeline = new TimelineMax();
    animationTimeline
      // .to(
      //   $(".dotBox"),
      //   {
      //     // duration: 1,
      //     "margin-left": "-25vw",
      //     width: "50vw",
      //     height: "50vw",
      //     // top: "150vh",
      //     opacity: 1,
      //     rotation: 180,
      //   },
      //   0
      // )
      // .to(
      //   $(".dotBox li"),
      //   {
      //     // duration: 1,
      //     width: 17,
      //     height: 17,
      //   },
      //   0
      // )

      // .to(
      //   $(".dot1"),
      //   {
      //     // duration: 1,
      //     "margin-left": 0,
      //     width: $(".wwwadots").width(),
      //     height: $(".wwwadots").width(),
      //     // top: dot1position,
      //     // left: $(dot1Ref.current).offset().left,
      //     opacity: 1,
      //   },
      //   0
      // )
      .to(
        $(".homesectionthreetitleanimation"),
        {
          // duration: 1,
          x: 0,
          opacity: 1,
        },
        0
      )

      // .to(
      //   $(".dot2"),
      //   {
      //     // duration: 1,
      //     "margin-left": 0,
      //     width: $(".wwwadots").width(),
      //     height: $(".wwwadots").width(),
      //     // top: dot3position,
      //     // left: $(dot3Ref.current).offset().left,
      //     opacity: 1,
      //   },
      //   0
      // )
      // .to(
      //   $(".dot3"),
      //   {
      //     // duration: 1,
      //     "margin-left": 0,
      //     width: $(".dot2-wwa").width(),
      //     height: $(".dot2-wwa").width(),
      //     // top: dot2position,
      //     // left: $(dot2Ref.current).offset().left,
      //     opacity: 1,
      //   },
      //   0
      // )
      .set(".dots", {
        opacity: 1,
      })
      // .to(
      //   $(".dotBox"),
      //   {
      //     // duration: 1,
      //     opacity: 0,
      //   },
      //   "-=0.5"
      // );

    // Set the timeline as the tween for the ScrollMagic scene
    scene1.setTween(animationTimeline);
    // Add the scene to the controller
    scene1.addTo(controllerMid);

    /* Service Init trigger Starts */
    var tlServInit = gsap.timeline();
    tlServInit
      .to(
        ".dots",
        {
          // duration: 0.01,
          opacity: 1,
        },
        0
      )
      // .to(
      //   $(".dot1"),
      //   {
      //     // duration: 1,
      //     // top: $(".hsdot-1").offset().top,
      //     // left: $(".hsdot-1").offset().left,
      //     width: $(".hsdot-1").width() + "px",
      //     height: $(".hsdot-1").width() + "px",
      //   },
      //   0
      // )
      // .to(
      //   $(".dot2"),
      //   {
      //     // duration: 1,
      //     // top: $(".hsdot-3").offset().top,
      //     // left: $(".hsdot-3").offset().left,
      //     width: $(".hsdot-1").width() + "px",
      //     height: $(".hsdot-1").width() + "px",
      //   },
      //   0
      // )
      // .to(
      //   $(".dot3"),
      //   {
      //     // duration: 1,
      //     // top: $(".hsdot-2").offset().top,
      //     // left: $(".hsdot-2").offset().left,
      //     width: $(".hsdot-1").width() + "px",
      //     height: $(".hsdot-1").width() + "px",
      //   },
      //   0
      // )
      .to(".hsdot", {
        // duration: 0.01,
        opacity: 1,
      })
      .to(".dots", {
        // duration: 0.01,
        opacity: 0,
      });

    new ScrollMagic.Scene({
      triggerElement: ".hs-service-1",
      triggerHook: 0.8,
      // duration: "50%",
      // reverse: false
    })
      .setTween(tlServInit)
      // .addIndicators({
      //     'name': 'Service Init'
      // })
      .addTo(controllerMid);
    /* Service Init trigger Ends */

    /* Service 1 trigger Starts */
    var tlServ1 = gsap.timeline();
    tlServ1.to(".hsTrigBtn", {
      // duration: 0.2,
      opacity: 0,
      y: 50,
    });

    new ScrollMagic.Scene({
      triggerElement: ".hs-service-1",
      triggerHook: 0.12,
      // reverse: false
    })
      .on("enter leave", function (e) {
        if (e.type === "enter") {
          TweenMax.staggerTo(
            ".h-serv-ul-1 p strong",
            0.35,
            {
              autoAlpha: 1,
              marginLeft: 0,
            },
            0.1
          );

          gsap.to(".hs-1-img", {
            duration: 0.2,
            opacity: 1,
            marginLeft: 0,
          });
        } else if (e.type === "leave") {
          TweenMax.staggerTo(
            ".h-serv-ul-1 p strong",
            0.35,
            {
              autoAlpha: 0,
              marginLeft: -50,
            },
            0.1
          );
          gsap.to(".hs-1-img", {
            duration: 0.2,
            opacity: 0,
            marginLeft: -50,
          });
        }
      })
      .setTween(tlServ1)
      // .addIndicators({
      //     'name': 'Service 1'
      // })
      .addTo(controllerMid);
    /* Service 1 trigger Ends */

    /* Service 2 trigger Starts */
    var tlServ2 = gsap.timeline();
    tlServ2.to(".hsTrigBtn", {
      duration: 0.2,
      opacity: 0,
      y: 50,
    });

    new ScrollMagic.Scene({
      triggerElement: ".hs-service-2",
      triggerHook: 0.12,
      // reverse: false
    })
      .on("enter leave", function (e) {
        if (e.type === "enter") {
          TweenMax.staggerTo(
            ".h-serv-ul-2 p strong",
            0.35,
            {
              autoAlpha: 1,
              marginLeft: 0,
            },
            0.1
          );

          gsap.to(".hs-2-img", {
            duration: 0.2,
            opacity: 1,
            marginLeft: 0,
          });
        } else if (e.type === "leave") {
          TweenMax.staggerTo(
            ".h-serv-ul-2 p strong",
            0.35,
            {
              autoAlpha: 0,
              marginLeft: -50,
            },
            0.1
          );
          gsap.to(".hs-2-img", {
            duration: 0.2,
            opacity: 0,
            marginLeft: -50,
          });
        }
      })
      .setTween(tlServ2)
      // .addIndicators({
      //     'name': 'Service 1'
      // })
      .addTo(controllerMid);
    /* Service 2 trigger Ends */

    /* Service 3 trigger Starts */
    var tlServ3 = gsap.timeline();
    tlServ3.to(".hsTrigBtn", {
      duration: 0.2,
      opacity: 0,
      y: 50,
    });

    new ScrollMagic.Scene({
      triggerElement: ".hs-service-3",
      triggerHook: 0.12,
      // reverse: false
    })
      .on("enter leave", function (e) {
        if (e.type === "enter") {
          TweenMax.staggerTo(
            ".h-serv-ul-3 p strong",
            0.35,
            {
              autoAlpha: 1,
              marginLeft: 0,
            },
            0.1
          );

          gsap.to(".hs-3-img", {
            duration: 0.2,
            opacity: 1,
            marginLeft: 0,
          });
        } else if (e.type === "leave") {
          TweenMax.staggerTo(
            ".h-serv-ul-3 p strong",
            0.35,
            {
              autoAlpha: 0,
              marginLeft: -50,
            },
            0.1
          );
          gsap.to(".hs-3-img", {
            duration: 0.2,
            opacity: 0,
            marginLeft: -50,
          });
        }
      })
      .setTween(tlServ3)
      // .addIndicators({
      //     'name': 'Service 1'
      // })
      .addTo(controllerMid);
    /* Service 3 trigger Ends */
    return () => {
      controllerMid.destroy();
      whoWeAreImgObserver.disconnect();
    };
  }, [dot1position, dot2position, dot3position]);
  return (
    <>
      {section3data.section_setting === true ? (
        <div className="section-spacing">
          <div className="container">

            {isLoading ? (
              <Conloader />
            ) : (
              <>
                <div className="row">
                  <div className="col-12 text-center">
                    <h2 className="page-title fw-bold homesectionthreetitleanimation">
                      {section3data.service_top_title}
                    </h2>
                    <div className="sub-title fw-bold homesectionthreetitleanimation">
                      {section3data.sevice_sub_title}
                    </div>
                  </div>
                </div>

                <div
                  className="row gx-md-5 mt-md-5 mt-4 mx-0 homesectionthreetitleanimation"
                  dangerouslySetInnerHTML={{
                    __html: section3data.service_description,
                  }}
                ></div>
              </>
            )}

            <div className="row mt-md-5 mt-4">
              <div className="col-lg-10 offset-lg-1 col-12">
                <div className="w100 wwr-animation fadeInUp">
                  <div className="h-img whoWeAreImg">
                    <div className="wwa wwa1">
                      <div className="wwwadots dot1-wwa" ref={dot1Ref}></div>
                      <img src={designleft} alt="Design Left" />
                    </div>
                    <div className="wwa wwa2">
                      <div className="wwwadots dot2-wwa" ref={dot2Ref}></div>
                      <img src={designcenter} alt="Design Center" />
                    </div>
                    <div className="wwa wwa3">
                      <div className="wwwadots dot3-wwa" ref={dot3Ref}></div>
                      <img src={designright} alt="Design Center" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-md-5 mt-4">
              <div className="col-md-10 offset-md-1 col-12">
                <div className="hs-box grid stopSec">
                  <div className="hs-service-trigger-1"></div>

                  <div className="hs-service hs-service-1 stopSec">
                    <div className="hs-service-in">
                      {section3data.service_list &&
                        section3data.service_list.length > 0 &&
                        section3data.service_list.map((key1, index) => {
                          if (index === 0) {
                            return (
                              <>
                                <h2 key={index}>
                                  <span className="hsDotBox">
                                    <img
                                      className={`hsdot hsdot-${index + 1}`}
                                      src={key1.select_icon?.url}
                                      alt="service img "
                                    />
                                  </span>
                                  <b>{key1.service_title}</b>
                                </h2>
                              </>
                            );
                          } else {
                            return (
                              <h2 className="hsTrigBtn" key={index}>
                                <span className="hsDotBox">
                                  <img
                                    className={`hsdot hsdot-${index + 1}`}
                                    src={key1.select_icon?.url}
                                    alt="service img"
                                  />
                                </span>
                                <b>{key1.service_title}</b>
                              </h2>
                            );
                          }
                        })}

                      {section3data.service_list &&
                        section3data.service_list.length > 0 &&
                        section3data.service_list.map((key1, index1) => {
                          if (index1 === 0) {
                            return (
                              <div key={index1} className="row">
                                <div className="col-md-6 col-12 mb-md-0 mb-4">
                                  {isLoading ? (
                                    <Conloader />
                                  ) : (
                                    <ul className="h-serv-ul-1">
                                      <li>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: key1.service_description,
                                          }}
                                        />
                                      </li>
                                    </ul>
                                  )}
                                </div>
                                <div className="col-md-6 col-12 text-center">
                                  {isLoading ? (
                                    <Imgloader />
                                  ) : (
                                    <img
                                      src={key1.service_image}
                                      className="hs-1-img doodles-image img-fluid"
                                      alt="Service"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        })}

                      <div></div>
                    </div>
                  </div>

                  {section3data.service_list &&
                    section3data.service_list.length > 0 &&
                    section3data.service_list.map((key1, index1) => {
                      if (index1 === 1) {
                        return (
                          <div className="hs-service hs-service-mob hs-service-2 stopSec">
                            <div className="hs-service-in">
                              <h2>
                                <span className="hsDotBox">
                                  {/* <span className="hsdot hsdot-2"></span> */}
                                  <img
                                    className="hsdot hsdot-2"
                                    src={key1.select_icon?.url}
                                    alt="service img"
                                  />
                                </span>
                                <b>{key1.service_title}</b>
                              </h2>
                              <div className="row">
                                <div className="col-md-6 col-12 mb-md-0 mb-4">
                                  {isLoading ? (
                                    <Conloader />
                                  ) : (
                                    <ul className="h-serv-ul-2">
                                      <li>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: key1.service_description,
                                          }}
                                        />
                                      </li>
                                    </ul>
                                  )}
                                </div>
                                <div className="col-md-6 col-12 text-center">
                                  {isLoading ? (
                                    <Imgloader />
                                  ) : (
                                    <img
                                      src={key1.service_image}
                                      className="hs-2-img doodles-image img-fluid"
                                      alt="Service"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      if (index1 === 2) {
                        return (
                          <div className="hs-service hs-service-mob hs-service-3 stopSec">
                            <div className="workTriggerStart"></div>
                            <div className="hs-service-in">
                              <h2>
                                <span className="hsDotBox">
                                  {/* <span className="hsdot hsdot-3 hsdot-3a"></span> */}
                                  <img
                                    className="hsdot hsdot-3"
                                    src={key1.select_icon?.url}
                                    alt="service img"
                                  />
                                </span>
                                <b>{key1.service_title}</b>
                              </h2>
                              <div className="row">
                                <div className="col-md-6 col-12 mb-md-0 mb-4">
                                  {isLoading ? (
                                    <Conloader />
                                  ) : (
                                    <ul className="h-serv-ul-3">
                                      <li>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: key1.service_description,
                                          }}
                                        />
                                      </li>
                                    </ul>
                                  )}
                                </div>
                                <div className="col-md-6 col-12 text-center">
                                  {isLoading ? (
                                    <Imgloader />
                                  ) : (
                                    <img
                                      src={key1.service_image}
                                      className="hs-3-img doodles-image img-fluid"
                                      alt="Service"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Homesectionthree;
