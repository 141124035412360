import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import playicon from "../img/homeimg/play.svg";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const CareersFive = (props) => {
  const { career5data } = props;
  const [homesection1, setHomesection1] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [aboutSocial, setAboutSocial] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);
  const handleClose = (e) => {
    const elements = document.querySelectorAll(".careers_heading");

    elements.forEach((element) => {
      element.innerText = "Submit your CV";
    });
    const formElement = document.getElementById("careerSubmit");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };

  const openPopup = (index) => {
    setCurrentImage(index);
  };

  const goToNextImage = () => {
    const nextImage = (currentImage + 1) % homesection1.length;
    setCurrentImage(nextImage);
  };

  const goToPrevImage = () => {
    const prevImage =
      (currentImage - 1 + homesection1.length) % homesection1.length;
    setCurrentImage(prevImage);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "ArrowRight") {
        goToNextImage();
      } else if (event.key === "ArrowLeft") {
        goToPrevImage();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [currentImage, homesection1.length]);

  const slug1 = "wp/v2/life-atlas/488";
  const slug2 = "wp/v2/pages?slug=contact&_fields=acf.contact_us_section";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    // Step 1: Fetch data from the initial REST API
    const fetchForm = async () => {
      try {
        const data = await apiCall(slug1, type);
        setHomesection1(data?.acf.lifeatatlasgallery);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchBanner = async () => {
      try {
        const data = await apiCall(slug2, type);
        data?.[0]?.acf?.contact_us_section?.map((data, key) => {
          if (data.acf_fc_layout === "our_office_section") {
            setAboutSocial(data);
          }
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchForm();
    fetchBanner();
  }, []);
  return (
    <>
      {career5data.section_settings === true ? (
        <div className="section-spacing atlasdark px-3">
          <div className="container abouteight">
            <div className="row">
              <div className="col-md-5 col-12 first-life">
                {isLoading ? (
                  <>
                    <Conloader />
                    <Imgloader />
                  </>
                ) : (<>
                  <div className="atlas-life d-flex flex-wrap">
                    <h3>{career5data.our_life_title}</h3>
                    <ul className="d-flex ">
                      {aboutSocial.social_media_details &&
                        aboutSocial.social_media_details.map(
                          (response, index) => {
                            return (
                              <li className="mx-2">
                                <Link
                                  style={{
                                    filter: "brightness(10) invert(10)",
                                  }}
                                  to={response?.social_link}
                                >
                                  <img
                                    style={{
                                      filter: "brightness(10) invert(10)",
                                    }}
                                    bg="white"
                                    width="30px"
                                    height="30px"
                                    src={response.logo}
                                    alt="facebook"
                                  />
                                </Link>
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </div>
                  <div>
                    <div
                      className="position-relative about-bg-video"
                      style={{
                        backgroundImage: `url(${career5data.video_image})`,
                      }}
                    >
                      <video
                        className="atlas_life_videos embed-responsive-item w-100 h-100"
                        controls
                        autoPlay
                        loop
                        muted
                        playsInline
                      >
                        <source
                          src={`${career5data?.video_link}`}
                          type="video/mp4"
                        />
                      </video>
                      <div className="about-videos-contact">
                        <Link
                          className="about-play"
                          data-bs-target="#careersModal"
                          data-bs-toggle="modal"
                        >
                          <img src={playicon} alt="Play" />
                        </Link>
                        <p className="join-team">{career5data.video_title}</p>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#FormModal"
                          onClick={handleClose}
                          className="btn btn-outline-primary btn-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.899"
                            height="19.555"
                            viewBox="0 0 11.899 19.555"
                          >
                            <path
                              id="Path_1094"
                              data-name="Path 1094"
                              d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                              transform="translate(-1119.884 -1672.268)"
                              fill="none"
                              stroke="#234285"
                            />
                          </svg>
                          {career5data.apply_button_link}
                        </Link>
                      </div>
                    </div>
                  </div>
                </>)}
              </div>
              <div className="col-md-7 col-12 second-life">
                <div className="row life_of_atlas_row">
                  {homesection1 &&
                    homesection1.map((res, index) => {
                      const pairIndex = Math.floor(index / 2);
                      return (
                        <div
                          key={index}
                          className={`life_of_atlas ${pairIndex % 2 === 0 ? "even" : "odd"
                            }`}
                        >
                          {isLoading ? (
                            <Imgloader />
                          ) : (
                            <figure>
                              <img
                                src={res}
                                alt="Play"
                                data-bs-toggle="modal"
                                data-bs-target="#galleryModal"
                                onClick={() => openPopup(index)}
                              />
                            </figure>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade show"
            id="careersModal"
            tabindex="-1"
            aria-labelledby="careersModalLabel"
            aria-modal="true"
            role="dialog"
          >
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="aboutModalLabel">
                    {career5data?.our_life_title}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="embed-responsive embed-responsive-16by9">
                    <video controls class="embed-responsive-item w-100">
                      <source
                        src={`${career5data?.video_link}`}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade gallerymodal"
            id="galleryModal"
            tabindex="-1"
            aria-labelledby="galleryModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                  <img
                    src={homesection1[currentImage]}
                    alt={homesection1[currentImage]}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                  <div className="navigation-buttons">
                    <button
                      type="button"
                      className="btn btn-primary previvon"
                      onClick={goToPrevImage}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 23.679 23.718"
                      >
                        <g
                          id="Component_26_3"
                          data-name="Component 26 – 3"
                          transform="translate(23.679) rotate(90)"
                        >
                          <g
                            id="Group_1"
                            data-name="Group 1"
                            clip-path="url(#clip-path)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M12.638,1.529V13.146l.034.024c.221-.228.44-.459.664-.685q.756-.761,1.516-1.517a.7.7,0,0,1,.8-.2.823.823,0,0,1,.528.682.636.636,0,0,1-.216.546l-1.876,1.877q-.878.879-1.757,1.758a.75.75,0,0,1-1.058-.061c-1.145-1.145-2.284-2.3-3.436-3.434a.764.764,0,0,1,.34-1.308.724.724,0,0,1,.743.248q1.029,1.038,2.066,2.068c.035.035.072.067.137.126.006-.09.011-.139.011-.189q0-3.094,0-6.188c0-2.025,0-4.05,0-6.074a.726.726,0,0,1,.54-.8,2.829,2.829,0,0,1,.682,0,6.445,6.445,0,0,1,.724.071c.545.092,1.09.19,1.632.3a10.781,10.781,0,0,1,2.558.931,11.522,11.522,0,0,1,1.773,1.1,11.616,11.616,0,0,1,2.21,2.149,11.034,11.034,0,0,1,1.536,2.571c.19.455.358.921.506,1.392a6.987,6.987,0,0,1,.217.981c.078.485.16.973.191,1.462a11.961,11.961,0,0,1,0,1.524c-.03.466-.117.928-.186,1.391a10.508,10.508,0,0,1-.675,2.349,11.853,11.853,0,0,1-1.09,2.082,12.216,12.216,0,0,1-1.665,2.039,11.6,11.6,0,0,1-2.962,2.109,11.341,11.341,0,0,1-1.878.731,11.634,11.634,0,0,1-2.881.468,10.3,10.3,0,0,1-1.961-.1c-.472-.073-.944-.156-1.406-.274a11.04,11.04,0,0,1-2.065-.759A12.04,12.04,0,0,1,5.012,21.4a12.337,12.337,0,0,1-3.865-4.672,11.382,11.382,0,0,1-.7-1.876,10.168,10.168,0,0,1-.271-1.288C.094,12.992.045,12.415,0,11.838a.668.668,0,0,1,.66-.738.747.747,0,0,1,.834.562A1.866,1.866,0,0,1,1.54,12a9.668,9.668,0,0,0,.195,1.708,10.845,10.845,0,0,0,.513,1.79,10.516,10.516,0,0,0,1.62,2.844A9.953,9.953,0,0,0,6.9,20.894a9.38,9.38,0,0,0,1.735.742,11.538,11.538,0,0,0,1.766.405,9.594,9.594,0,0,0,2.088.079,14.6,14.6,0,0,0,1.572-.234,9.2,9.2,0,0,0,2.385-.819A10.032,10.032,0,0,0,19.8,18.451,10.18,10.18,0,0,0,21.2,16.274a9.765,9.765,0,0,0,.686-1.939,8.023,8.023,0,0,0,.257-1.565c.019-.583.064-1.168.028-1.748a9.753,9.753,0,0,0-.249-1.611A9.861,9.861,0,0,0,20.537,6.2a10.377,10.377,0,0,0-3.45-3.254,10.528,10.528,0,0,0-1.975-.9,9.083,9.083,0,0,0-1.8-.426c-.216-.026-.43-.058-.671-.091"
                              fill="#ffffff"
                              transform="translate(-0.001 0)"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary nexticon"
                      onClick={goToNextImage}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 23.679 23.718"
                      >
                        <g
                          id="Component_26_3"
                          data-name="Component 26 – 3"
                          transform="translate(23.679) rotate(90)"
                        >
                          <g
                            id="Group_1"
                            data-name="Group 1"
                            clip-path="url(#clip-path)"
                          >
                            <path
                              id="Path_1"
                              data-name="Path 1"
                              d="M12.638,1.529V13.146l.034.024c.221-.228.44-.459.664-.685q.756-.761,1.516-1.517a.7.7,0,0,1,.8-.2.823.823,0,0,1,.528.682.636.636,0,0,1-.216.546l-1.876,1.877q-.878.879-1.757,1.758a.75.75,0,0,1-1.058-.061c-1.145-1.145-2.284-2.3-3.436-3.434a.764.764,0,0,1,.34-1.308.724.724,0,0,1,.743.248q1.029,1.038,2.066,2.068c.035.035.072.067.137.126.006-.09.011-.139.011-.189q0-3.094,0-6.188c0-2.025,0-4.05,0-6.074a.726.726,0,0,1,.54-.8,2.829,2.829,0,0,1,.682,0,6.445,6.445,0,0,1,.724.071c.545.092,1.09.19,1.632.3a10.781,10.781,0,0,1,2.558.931,11.522,11.522,0,0,1,1.773,1.1,11.616,11.616,0,0,1,2.21,2.149,11.034,11.034,0,0,1,1.536,2.571c.19.455.358.921.506,1.392a6.987,6.987,0,0,1,.217.981c.078.485.16.973.191,1.462a11.961,11.961,0,0,1,0,1.524c-.03.466-.117.928-.186,1.391a10.508,10.508,0,0,1-.675,2.349,11.853,11.853,0,0,1-1.09,2.082,12.216,12.216,0,0,1-1.665,2.039,11.6,11.6,0,0,1-2.962,2.109,11.341,11.341,0,0,1-1.878.731,11.634,11.634,0,0,1-2.881.468,10.3,10.3,0,0,1-1.961-.1c-.472-.073-.944-.156-1.406-.274a11.04,11.04,0,0,1-2.065-.759A12.04,12.04,0,0,1,5.012,21.4a12.337,12.337,0,0,1-3.865-4.672,11.382,11.382,0,0,1-.7-1.876,10.168,10.168,0,0,1-.271-1.288C.094,12.992.045,12.415,0,11.838a.668.668,0,0,1,.66-.738.747.747,0,0,1,.834.562A1.866,1.866,0,0,1,1.54,12a9.668,9.668,0,0,0,.195,1.708,10.845,10.845,0,0,0,.513,1.79,10.516,10.516,0,0,0,1.62,2.844A9.953,9.953,0,0,0,6.9,20.894a9.38,9.38,0,0,0,1.735.742,11.538,11.538,0,0,0,1.766.405,9.594,9.594,0,0,0,2.088.079,14.6,14.6,0,0,0,1.572-.234,9.2,9.2,0,0,0,2.385-.819A10.032,10.032,0,0,0,19.8,18.451,10.18,10.18,0,0,0,21.2,16.274a9.765,9.765,0,0,0,.686-1.939,8.023,8.023,0,0,0,.257-1.565c.019-.583.064-1.168.028-1.748a9.753,9.753,0,0,0-.249-1.611A9.861,9.861,0,0,0,20.537,6.2a10.377,10.377,0,0,0-3.45-3.254,10.528,10.528,0,0,0-1.975-.9,9.083,9.083,0,0,0-1.8-.426c-.216-.026-.43-.058-.671-.091"
                              fill="#ffffff"
                              transform="translate(-0.001 0)"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CareersFive;
