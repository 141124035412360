import React, { useState, useEffect } from "react";
import Button from "../button/button";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { apiCall } from "../authentication";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import image from "../img/placeholder-1-1.webp";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

function Servicesectionseven({ article }) {
  const [allArticleData, setAllArticleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryDataMap, setCategoryDataMap] = useState({});
  let selectedIds = [];

  if (article && Array.isArray(article.select_articles)) {
    selectedIds = article?.select_articles?.map((article) => article.ID);
  }
  const stringIds = selectedIds.toString();

  const slug = `wp/v2/articles?include=${stringIds}&_fields=id,title,_links,slug,date,_embedded.wp:featuredmedia,_embedded.wp:term&_embed`;
  const type = "get";
  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const dataArray = await apiCall(slug, type);

        setAllArticleData(dataArray);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchArticleData();

    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".serviceblogsection",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation4"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);
    fetchArticleData();

    return () => {
      controller.destroy();
    };
  }, [article, categoryDataMap]);

  return (
    <>
      <div>
        <div>
          {article?.section_setting === true && (
            <div className="section-spacing">
              <div className="container">
                <div className="row justify-content-between">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <>
                      <h2 className="col-md-6 col-12 page-title fw-bold mb-md-5 mb-3">
                        {article?.article_main_title}
                      </h2>
                      <Button
                        classadd="col-md-6 col-12 text-md-end mb-md-0 mb-5"
                        name={article?.article_button_name?.title}
                        link={article?.article_button_name?.url}
                        target={article?.article_button_name?.target}
                      />
                    </>
                  )}
                </div>
                <div className="row">
                  {allArticleData?.map((innerArticle) => {
                    return (
                      <div
                        key={innerArticle.slug}
                        className="artical-card dot-animation"
                      >
                        <Link to={`/articles/${innerArticle.slug}`}>
                          {isLoading ? (
                            <Imgloader />
                          ) : (
                            <div className="artical-card-img">
                              {innerArticle?._embedded?.[
                                "wp:featuredmedia"
                              ]?.[0]?.source_url ? (
                                <img
                                  src={
                                    innerArticle?._embedded?.[
                                      "wp:featuredmedia"
                                    ]?.[0]?.source_url
                                  }
                                  alt={
                                    innerArticle?._embedded?.[
                                      "wp:featuredmedia"
                                    ]?.[0]?.name
                                  }
                                />
                              ) : (
                                <img src={image} alt="image" />
                              )}
                            </div>
                          )}
                          {isLoading ? (
                            <Conloader />
                          ) : (

                            <>
                              <div className="row justify-content-between align-items-center artical-card-head">
                                <div className="col">
                                  <p>
                                    {
                                      innerArticle?._embedded?.[
                                        "wp:term"
                                      ]?.[0]?.[0]?.name
                                    }
                                  </p>
                                </div>
                                <div className="col">
                                  <div className="d-flex justify-content-end dot-flex-testi">
                                    <div className="dot-testi light-green-testi"></div>
                                    <div className="dot-testi green-testi"></div>
                                    <div className="dot-testi blue-testi"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="artical-card-title">
                                <h3>{innerArticle?.title?.rendered}</h3>
                                <p className="artical-card-date">
                                  {formatDate(innerArticle?.date)}
                                </p>
                              </div>
                            </>
                          )}

                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div >
            </div >
          )
          }
        </div >
      </div >
    </>
  );
}
async function getCategory(categoryId, http) {
  try {
    const response = await http.get(`wp/v2/article-type/${categoryId}`);
    if (response.status !== 200) {
      throw new Error(`Network response was not ok (${response.status})`);
    }
    const data = await response.data;
    return data;
  } catch (error) {
    console.error("Error fetching category data:", error);
    return null;
  }
}
function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(inputDate);
  return date.toLocaleDateString("en-US", options);
}

export default Servicesectionseven;
