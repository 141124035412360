import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const PortfolioInnerFour = ({ hire12inner, hire12innerdata, post }) => {
  const [portfolioPost, setPortfolioPost] = useState([]);
  const [estimationcontent, setEstimationcontent] = useState([]);
  const [portfoliBanner, setPortfolioBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let selectedIds = [];
  let stringIds = "";
  if (
    post?.acf?.common_section &&
    Array.isArray(post?.acf?.common_section?.[0].select_portfolio_project)
  ) {
    selectedIds = post?.acf?.common_section?.[0].select_portfolio_project?.map(
      (section9data) => section9data
    );
    stringIds = selectedIds.toString();
  }
  const slug1 = `wp/v2/project?_embed&include=${stringIds}`;
  const type = "get";
  const fetchPost = async () => {
    try {
      const data = await apiCall(slug1, type);
      setPortfolioPost(data);
      data?.[0].acf?.common_section.map((item, index) => {
        if (item?.acf_fc_layout === "cta_2_section") {
          setEstimationcontent(item);
        }
      });
      // setEstimationcontent(data?.[0].acf?.common_section);
    } catch (error) {
      console.error("Error fetching portflioPost:", error);
    }
  };

  useEffect(() => {
    // Assuming 'id' is defined elsewhere in your component
    post?.acf?.common_section.map((item, index) => {
      if (item?.acf_fc_layout === "portfolio_project_section") {
        setPortfolioBanner(item);
      }
    });
    fetchPost();
  }, []);

  useEffect(() => {
    if (portfoliBanner.section_setting === true) {
      setIsLoading(false);
    }
  }, [portfoliBanner.section_setting]);

  return (
    <div className="section-spacing pt-0">
      {portfoliBanner?.portfolio_title !== null &&
        portfoliBanner?.portfolio_button !== "" ? (
        <section id="recent-work">
          <div className="container">
            <div className="row mx-0 p-0 justify-content-end align-items-center recent-work">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  <div className="col-md-6 p-0">
                    <h5>{portfoliBanner?.portfolio_title}</h5>
                  </div>
                  <div className="col-md-6 p-0 recent-btn">
                    <Link
                      className="btn button-com"
                      to={portfoliBanner?.portfolio_button?.url}
                    >
                      <svg

                        xmlns="http://www.w3.org/2000/svg"
                        width="11.899"
                        height="19.555"
                        viewBox="0 0 11.899 19.555"
                      >
                        <path
                          id="Path_1094"
                          data-name="Path 1094"
                          d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                          transform="translate(-1119.884 -1672.268)"
                          fill="none"
                          stroke="#fff"
                        ></path>
                      </svg>
                      {portfoliBanner?.portfolio_button?.title}
                    </Link>
                  </div>
                </>
              )}
            </div>
            <div className="work_card">
              {portfolioPost !== null &&
                portfolioPost.map((res, key) => {
                  return (
                    <>
                      <div key={key} className="work_card-inner">
                        {isLoading ? (
                          <Imgloader />
                        ) : (
                          <figure>
                            <Link to={"/work/" + res.slug}>
                              <img
                                src={res?.yoast_head_json?.og_image?.[0]?.url}
                                alt=""
                              />
                            </Link>
                            <div className="dots">
                              <span className="green"></span>
                              <span className="black"></span>
                              <span className="blue"></span>
                            </div>
                          </figure>
                        )}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </section>
      ) : null}

      {hire12inner !== "hire-inner" &&
        estimationcontent?.section_setting === true ? (
        <section className="let-discuss-section">
          <div className="container">
            {isLoading ? (
              <Conloader />
            ) : (
              <div className="inner-content text-center">
                <div className="title-box">
                  <h2>{estimationcontent?.cta_2_title}</h2>
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: estimationcontent?.cta_2_content,
                  }}
                ></p>
                <Link
                  to="/contact"
                  className="comman-btn top-space white-bg"
                  title="Request and Estimate"
                >
                  {estimationcontent?.cta_2_button?.title}
                </Link>
              </div>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PortfolioInnerFour;
