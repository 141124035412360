import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";

function HireInnerfour(props) {
  const { hire4inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Load Calendly widget script
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      // Remove the script when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  // Function to open Calendly popup
  const openCalendly = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url:
          "https://calendly.com/atlas-softweb/30min?hide_gdpr_banner=1&primary_color=73b51a",
      });
    }
  };

  useEffect(() => {

    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: '.let-discuss-section',
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start      
      duration: "50%",
      reverse: true,
    })


    var animationTimeline = new TimelineMax();
    animationTimeline
      .to(
        $(".hireinneranimation4"),
        {
          x: 0, // Adjust the value as needed
          opacity: 1,
          ease: Power0.easeNone,
        },
        0
      )


    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);


    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire4inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire4inner.section_setting]);

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://assets.calendly.com/assets/external/widget.css"
        />
      </Helmet>
      {hire4inner.section_setting && hire4inner.section_setting === true ? (
        <section className="let-discuss-section">
          <div className="container hireinneranimation4">
            {isLoading ? (
              <Conloader />
            ) : (
              <>
                <div className="inner-content text-center">
                  <div className="title-box">
                    <h2>{hire4inner.cta_2_title}</h2>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{ __html: hire4inner.cta_2_content }}
                  ></p>
                  <Link
                    to="javascript:;"
                    onClick={openCalendly}
                    className="comman-btn top-space white-bg"
                    target={hire4inner.cta_2_button.target}
                    title={hire4inner.cta_2_button.title}
                  >
                    {hire4inner.cta_2_button.title}
                  </Link>
                </div>
              </>
            )}
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnerfour;
