import axios from "axios";
import { handleLogin } from "./App";

const allEvents = () => {
  const handleMouseOver = () =>
    document.querySelector(".ring").classList.add("hovered");
  const handleMouseOut = () =>
    document.querySelector(".ring").classList.remove("hovered");
  const handleMouseSOver = () =>
    document.querySelector(".ring").classList.add("slidered");
  const handleMouseSOut = () =>
    document.querySelector(".ring").classList.remove("slidered");

  const addListeners = (elements, mouseOverHandler, mouseOutHandler) => {
    elements.forEach((element) => {
      element.addEventListener("mouseover", mouseOverHandler);
      element.addEventListener("mouseout", mouseOutHandler);
    });
  };

  const links = document.querySelectorAll("a, button");
  const slinks = document.querySelectorAll(".slick-list");

  addListeners(links, handleMouseOver, handleMouseOut);
  addListeners(slinks, handleMouseSOver, handleMouseSOut);

  return () => {
    addListeners(links, handleMouseOver, handleMouseOut);
    addListeners(slinks, handleMouseSOver, handleMouseSOut);
  };
};

const baseURL = "https://wpatlas.atlassoftweb.com/atlasapi/";
const instance = axios.create({
  baseURL,
  maxBodyLength: Infinity,
});
export const apiCall = async (slug, type, data) => {
  try {
    let token = localStorage.getItem("jwtToken");

    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await instance({
      method: type,
      url: `${baseURL}${slug}`,
      ...(type.toLowerCase() === "post" && { data }),
    });
    if (window.scrollY === 0) {
      allEvents();
    }

    return data === "blog" ? response : response.data;
  } catch (error) {
    if (error.response.status === 400 || error.response.status === 403) {
      try {
        localStorage.clear();
        await handleLogin();
        return apiCall(slug, type, data);
      } catch (loginError) {
        // Handle login error
        console.error("Login failed:", loginError);
        throw loginError;
      }
    }
    console.error("API Error:", error);
    throw error;
  }
};

export const imageUpdate = () => {
  var siteTheme = localStorage.getItem("siteTheme");
  var imageClass = document.querySelectorAll(".doodles-image");
  if (imageClass.length > 0) {
    imageClass.forEach((element) => {
      if (siteTheme === "dark") {
        var image = element.src.split(".svg");
        var path = image[0].replaceAll("-black", "");
        element.src = path + "-black.svg";
      } else if (siteTheme === "light") {
        var image = element.src.replaceAll("-black", "");
        element.src = image;
      } else {
        var image = element.src.replaceAll("-black", "");
        element.src = image;
      }
    });
  }
};
