import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";

const HireInnertwelve = ({ hire12inner, hire12innerdata }) => {
  const [portfolioPost, setPortfolioPost] = useState([]);
  const [estimationcontent, setEstimationContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let stringIds = "";
  let selectedIds = [];
  const [error, setError] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleClose = () => {
    const formElement = document.getElementById("quoteSubmit");
    const numericInputs = document.getElementsByClassName("numericInput");

    for (let i = 0; i < numericInputs.length; i++) {
      numericInputs[i].addEventListener("keypress", checkInputValue);
    }
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
  };

  const checkInputValue = (e) => {
    const input = document.querySelector(".form-control.numericInput");

    // Check if input exists and the value length is already 10
    if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
      e.preventDefault();
      return;
    }
    // Get the entered key
    const key = String.fromCharCode(e.keyCode);

    // Check if the entered key is a number
    const isNumber = /[0-9]/.test(key);

    // Prevent the input if the entered key is not a number
    if (!isNumber) {
      e.preventDefault();
    }
  };

  if (hire12innerdata.select_portfolio_project) {
    selectedIds = hire12innerdata.select_portfolio_project?.map(
      (article) => article
    );
    stringIds = selectedIds.toString();
  }

  const slug1 = `wp/v2/project?include=${stringIds}&_fields=id,acf,slug,_links,_embedded.wp:featuredmedia&_embed`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPost = async () => {
      try {
        const data = await apiCall(slug1, type);
        setPortfolioPost(data);
        setEstimationContent(data?.[0].acf?.common_section);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching portflioPost:", error);
      }
    };
    fetchPost();
  }, []);
  return (
    <div>
      <div>
        {hire12innerdata?.section_setting === true ||
          (hire12inner !== "hire-inner" &&
            estimationcontent?.[0]?.section_setting === true) ? (
          <div className="section-spacing">
            <section id="recent-work">
              <div className="container">
                <div className="row mx-0 p-0 justify-content-end align-items-center recent-work">
                  {isLoading ? (
                    <Conloader />
                  ) : (
                    <>
                      <div className="col-md-6 p-0">
                        <h5>{hire12innerdata?.portfolio_title}</h5>
                      </div>
                      <div className="col-md-6 p-0 recent-btn">
                        <Link
                          className="btn button-com"
                          to={hire12innerdata?.portfolio_button?.url}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="11.899"
                            height="19.555"
                            viewBox="0 0 11.899 19.555"
                          >
                            <path
                              id="Path_1094"
                              data-name="Path 1094"
                              d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                              transform="translate(-1119.884 -1672.268)"
                              fill="none"
                              stroke="#fff"
                            ></path>
                          </svg>
                          {hire12innerdata?.portfolio_button?.title}
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <div className="work_card">
                  {portfolioPost !== null &&
                    portfolioPost?.map((res, key) => {
                      return (
                        <>
                          {isLoading ? (
                            <Conloader />
                          ) : (
                            <>
                              <div key={key} className="work_card-inner">
                                <figure>
                                  <Link to={"/work/" + res.slug}>
                                    <img
                                      src={
                                        res?._embedded["wp:featuredmedia"]?.[0]
                                          .source_url
                                      }
                                      alt={res?.title?.rendered}
                                    />
                                  </Link>
                                  <div className="dots">
                                    <span className="green"></span>
                                    <span className="black"></span>
                                    <span className="blue"></span>
                                  </div>
                                </figure>
                              </div>
                            </>
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </section>

            <section className="let-discuss-section">
              <div className="container">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="inner-content text-center">
                      <div className="title-box">
                        <h2>{estimationcontent?.[0]?.cta_2_title}</h2>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: estimationcontent?.[0]?.cta_2_content,
                        }}
                      ></p>
                      <Link
                        // to={estimationcontent?.[0]?.cta_2_button?.url}
                        className="comman-btn top-space white-bg"
                        data-bs-toggle="modal"
                        data-bs-target="#HeaderFormModal"
                        to="javascript::"
                        onClick={handleClose}
                      >
                        {estimationcontent?.[0]?.cta_2_button?.title}
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </section>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HireInnertwelve;
