import React from "react";
import "./Thankyou.css";
import { Link } from "react-router-dom";

function ThankyouCareers() {


  return (
    <div className="container section-spacing" id="Thankyou">
      <div className="mt-5">
        <div className="thank-icon mb-2">
          <svg width="115px" height="115px" viewBox="0 0 133 133" version="1.1">
            <g
              id="check-group"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <circle
                id="filled-circle"
                fill="#a2ba5f"
                cx="66.5"
                cy="66.5"
                r="54.5"
              />
              <circle
                id="white-circle"
                fill="#FFFFFF"
                cx="66.5"
                cy="66.5"
                r="55.5"
              />
              <circle
                id="outline"
                stroke="#a2ba5f"
                stroke-width="4"
                cx="66.5"
                cy="66.5"
                r="54.5"
              />
              <polyline
                id="check"
                stroke="#FFFFFF"
                stroke-width="5.5"
                points="41 70 56 85 92 49"
              />
            </g>
          </svg>
        </div>
        <h3>Thanks for considering a career with Atlas!</h3>

              <p className="link-thank mb-3">
                Your application is in good hands, and our HR team will be in
                touch soon. Explore our <Link to="/careers">careers</Link> page
                for more info, and feel free to reach out to{" "}
                <Link to="mailto:hrdept@atlassoftweb.com">
                  hrdept@atlassoftweb.com
                </Link>{" "}
                with any questions. Excited to potentially welcome you to the
                Atlas family!
              </p>
      </div>
    </div>
  );
}

export default ThankyouCareers;
