import React, { useState, useEffect } from "react";
import Button from "../button/button";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import { apiCall } from "../authentication";
import image from "../img/placeholder-1-1.webp";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

function BlogDetailthree({ article }) {
  const [allArticleData, setAllArticleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let selectedIds = [];
  if (article && Array.isArray(article.related_project)) {
    selectedIds = article?.related_project?.map((article) => article.ID);
  }

  const stringIds = selectedIds.toString();
  const slug1 = `wp/v2/posts?include=${stringIds}&_fields=id,title,_links,slug,date,_embedded.wp:featuredmedia,_embedded.wp:term&_embed`;
  const type = "get";
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await apiCall(slug1, type);
        setAllArticleData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching portflioPost:", error);
      }
    };

    fetchPost();
  }, [article]);

  return (
    <>
      <div>
        <div>
          {article && (
            <div className="section-spacing">
              <div className="container">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <div className="row justify-content-between">
                      <h2 className="col-md-6 col-12 page-title fw-bold mb-md-5 mb-3">
                        {article?.main_title}
                      </h2>
                      <Button
                        classadd="col-md-6 col-12 text-md-end mb-md-0 mb-5"
                        name={article?.view_btn?.title}
                        link={article?.view_btn?.url}
                        target={article?.view_btn?.target}
                      />
                    </div>
                  </>
                )}
                <div className="row justify-content-between">
                  {allArticleData?.map((innerArticle, index) => {
                    return (
                      <div
                        key={innerArticle.slug}
                        className="artical-card dot-animation"
                      >
                        {isLoading ? (
                          <Imgloader />
                        ) : (
                          <>
                            <Link to={`/blog/${innerArticle.slug}`}>
                              <div className="artical-card-img">
                                {innerArticle?._embedded?.[
                                  "wp:featuredmedia"
                                ]?.[0]?.source_url ? (
                                  <img
                                    src={
                                      innerArticle?._embedded?.[
                                        "wp:featuredmedia"
                                      ]?.[0]?.source_url
                                    }
                                    alt={
                                      innerArticle?._embedded?.[
                                        "wp:featuredmedia"
                                      ]?.[0]?.name
                                    }
                                  />
                                ) : (
                                  <img src={image} alt="image" />
                                )}
                              </div>
                              <div className="row justify-content-between align-items-center artical-card-head">
                                <div className="col">
                                  <p>
                                    {
                                      innerArticle?._embedded?.[
                                        "wp:term"
                                      ]?.[0]?.[0]?.name
                                    }
                                  </p>
                                </div>
                                <div className="col">
                                  <div className="d-flex justify-content-end dot-flex-testi">
                                    <div className="dot-testi light-green-testi"></div>
                                    <div className="dot-testi green-testi"></div>
                                    <div className="dot-testi blue-testi"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="artical-card-title">
                                <h3>{innerArticle?.title?.rendered}</h3>
                                <p className="artical-card-date">
                                  {formatDate(innerArticle?.date)}
                                </p>
                              </div>
                            </Link>
                          </>
                        )
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div >
    </>
  );
}

function formatDate(inputDate) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(inputDate);
  return date.toLocaleDateString("en-US", options);
}

export default BlogDetailthree;
