import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";

const Contactthree = (props) => {
  const { contact3data } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (contact3data.section_settings === true) {
      setIsLoading(false);
    }
  }, [contact3data.section_settings]);
  useEffect(() => {
    // Load Calendly widget script
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    // Append script to head
    document.head.appendChild(script);

    // Load Calendly widget CSS after a delay
    const loadCalendlyCSS = () => {
      const link = document.createElement("link");
      link.href = "https://assets.calendly.com/assets/external/widget.css";
      link.rel = "stylesheet";
      document.head.appendChild(link);
    };

    // Set a delay before loading CSS (adjust the delay duration if needed)
    const delay = 2000; // 2 seconds delay
    const timerId = setTimeout(loadCalendlyCSS, delay);

    return () => {
      // Clear the timer and remove the script and link when the component is unmounted
      clearTimeout(timerId);
      document.head.removeChild(script);
    };
  }, []);

  // Function to open Calendly popup
  const openCalendly = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/atlas-softweb/30min?hide_gdpr_banner=1&primary_color=73b51a",
      });
    }
  };

  const [isPanel1Open, setPanel1Open] = useState(true);
  const [isPanel2Open, setPanel2Open] = useState(false);
  const togglePanel1 = () => {
    setPanel1Open(!isPanel1Open);
    // Close Panel 2 if it's open
    if (isPanel2Open) {
      setPanel2Open(false);
    }
  };

  const togglePanel2 = () => {
    setPanel2Open(!isPanel2Open);
    // Close Panel 1 if it's open
    if (isPanel1Open) {
      setPanel1Open(false);
    }
  };

  const handleLinkClick = () => {
    // Assuming tidioChatApi is globally accessible
    if (window.tidioChatApi) {
      window.tidioChatApi.display(true);
      window.tidioChatApi.open();
    }
  };
  const handleScrollClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // If you also want to trigger other actions, you can add them here
  };

  return (
    <>
      {contact3data.section_settings === true ? (
        <section className="hire-apply-job-section section-spacing">
          <div className="container">
            {isLoading ? (
              <Conloader />
            ) : (
              <ul className="nav nav-tabs" id="hire-apply-job" role="tablist">
                {contact3data &&
                  contact3data?.job_process_details &&
                  contact3data?.job_process_details.map((res, index) => {
                    return (
                      <li className="nav-item" role="presentation">
                        <Link
                          onClick={
                            index === 0
                              ? togglePanel1
                              : index === 1
                                ? togglePanel2
                                : togglePanel2
                          }
                          className={`nav-link  ${index === 0 ? "active" : index === 1 ? "" : "active"
                            }`}
                          id={index === 0 ? "hire-a-talent" : "apply-for-a-job"}
                          data-bs-toggle="tab"
                          href={
                            index === 0
                              ? "#hire-a-talent-tab"
                              : "#apply-for-a-job-tab"
                          }
                          role="tab"
                          aria-controls={
                            index === 0
                              ? "hire-a-talent-tab"
                              : "apply-for-a-job-tab"
                          }
                          aria-selected={index === 0 ? "true" : "false"}
                        >
                          {res.job_step_name}
                        </Link>
                      </li>
                    );
                  })}
                <>
                  {/* <li className="nav-item" role="presentation">
                            <Link className="nav-link active" id="hire-a-talent" data-bs-toggle="tab" href="#hire-a-talent-tab"
                                role="tab" aria-controls="hire-a-talent-tab" aria-selected="true">Hire a Talent</Link>

                        </li>
                        <li className="nav-item" role="presentation">
                            <Link className="nav-link" id="apply-for-a-job" data-bs-toggle="tab" href="#apply-for-a-job-tab" role="tab"
                                aria-controls="apply-for-a-job-tab" aria-selected="false">Apply for a Job</Link>
                        </li> */}
                </>
              </ul>
            )}

            <div className="tab-content" id="hire-apply-job-content">
              {isPanel1Open && (
                <div
                  className="tab-pane fade show active"
                  id="hire-a-talent-tab"
                  role="tabpanel"
                  aria-labelledby="hire-a-talent"
                >
                  <div className="hire-info">
                    {contact3data &&
                      contact3data?.job_process_details[0].job_step_details &&
                      contact3data?.job_process_details[0].job_step_details.map(
                        (res, index) => {
                          return (
                            <div className="box dot-animation position-relative contactlinkabsolute">
                              {isLoading ? (
                                <Imgloader />
                              ) : (
                                <>
                                  <div className="d-flex">
                                    <img src={res.job_icon} alt={res.job_content} />
                                    {/* <img src={res.job_icon} alt={res.job_icon} /> */}
                                    <p>
                                      {res.job_content ===
                                        "Schedule a consultation call" ? (
                                        <Link
                                          to="javascript:;"
                                          onClick={openCalendly}
                                        >
                                          {res.job_content}
                                        </Link>
                                      ) : res.job_content ===
                                        "Chat with our representative" ? (
                                        <Link
                                          to="javascript:;"
                                          onClick={handleLinkClick}
                                        >
                                          {res.job_content}
                                        </Link>
                                      ) : res.job_content ===
                                        "Send us an inquiry" ? (
                                        <Link
                                          to="javascript:;"
                                          onClick={handleScrollClick}
                                        >
                                          {res.job_content}
                                        </Link>
                                      ) : res.job_content ===
                                        "Raise a hiring request" ? (
                                        <Link
                                          to="javascript:;"
                                          onClick={handleScrollClick}
                                        >
                                          {res.job_content}
                                        </Link>
                                      ) : (
                                        <Link to={res.job_link}>
                                          {res.job_content}
                                        </Link>
                                      )}
                                    </p>
                                  </div>
                                  <div className="d-flex w-100 dot-flex mt-md-4 mt-2 justify-content-end mb-0">
                                    <div className="dot-sec mb-0"></div>
                                    <div className="dot-sec1 mb-0"></div>
                                    <div className="dot-sec2 mb-0"></div>
                                  </div>
                                </>)}
                            </div>
                          );
                        }
                      )}
                  </div>
                </div>
              )}
              {isPanel2Open && (
                <div
                  className="tab-pane fade show active"
                  id="apply-for-a-job-tab"
                  role="tabpanel"
                  aria-labelledby="apply-for-a-job"
                >
                  <div className="hire-info">
                    {contact3data &&
                      contact3data?.job_process_details[1].job_step_details &&
                      contact3data?.job_process_details[1].job_step_details.map(
                        (res, index) => {
                          return (
                            <div className="box dot-animation">
                              {isLoading ? (
                                <Imgloader />
                              ) : (
                                <>
                                  <div className="d-flex">
                                    <img
                                      src={res.job_icon}
                                      alt="schedule-discovery-call.svg"
                                    />
                                    {/* <img src={res.job_icon} alt={res.job_icon} /> */}
                                    <p>
                                      <Link to={res.job_link}>
                                        {res.job_content}
                                      </Link>
                                    </p>
                                  </div>
                                  <div className="d-flex w-100 dot-flex mt-md-4 mt-2 justify-content-end mb-0">
                                    <div className="dot-sec mb-0"></div>
                                    <div className="dot-sec1 mb-0"></div>
                                    <div className="dot-sec2 mb-0"></div>
                                  </div>
                                </>
                              )}
                            </div>

                          );
                        }
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Contactthree;
