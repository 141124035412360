import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Testimonialone from "./testimonialpage/Testimonialone";
import Loader from "./Loader";
import "../src/Testimonial.css";
import { apiCall } from "./authentication";
import CommonCta from "./CommonCta";

const Testimonial = () => {
  const [users7, setUsers7] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [testsection1, setTestsection1] = useState([]);
  const [testsection2, setTestsection2] = useState([]);
  const [datapartner, setDatapartner] = useState([]);
  const [yoastData, setYoastData] = useState({});
  const slug1 = "wp/v2/pages?slug=testimonial";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPost = async () => {
      try {
        const data = await apiCall(slug1, type);
        setUsers7(data);
        setTestsection1(data[0]?.acf.common_section[0]);
        setTestsection2(data[0]?.acf.testimonial_counter_section[0]);
        setYoastData(data[0]?.yoast_head_json);

        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout == "banner_section") {
            setTestsection1(element);
          }
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPost();
  }, []);

  return (
    <>
      <div>
        {isLoading ? (
          <></>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={yoastData.og_url}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <Testimonialone test1data={testsection1} test2data={testsection2} />
            <CommonCta />
          </div>
        )}
      </div>
    </>
  );
};

export default Testimonial;
