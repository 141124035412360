import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Imgloader from "../componentsheaderfooter/Imgloader";

const CareersTwo = ({ careers9data, careers2data }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [error, setError] = useState([]);

  const handleClose = (e) => {
    const formElement = document.getElementById("careerSubmit");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
    let designation = e.target.dataset.id;
    const elements = document.querySelectorAll(".careers_heading");

    elements.forEach((element) => {
      element.innerText = designation;
    });
    const designationField = document.querySelector('[name="hidefield"]');

    if (designationField) {
      designationField.value = designation;
    }
  };

  useEffect(() => {
    if (careers9data === true) {
      setIsLoading(false);
    }
  }, [careers9data]);

  return (
    <>
      <div>
        <div className="container">
          <div className="row align-items-stretch">
            {careers2data !== null &&
              careers2data &&
              careers2data.length > 0 &&
              careers2data.map((myjob, index) => {
                return (
                  <>
                    <div className="col-md-4 col-sm-6 col-12 mb-5 mt-md-5">
                      {isLoading ? (
                        <Imgloader />
                      ) : (
                        <>
                          <div
                            className={`wordpress-box ${index % 3 === 1
                              ? "blue-box"
                              : index % 3 === 2
                                ? "black-box"
                                : ""
                              }`}
                            key={index}
                          >
                            <div className="padding-box">
                              <div>
                                <img
                                  src={
                                    myjob?._embedded?.["wp:featuredmedia"]?.[0]
                                      ?.source_url
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                                <h3 className="designation-title">
                                  {myjob?.title.rendered}
                                </h3>
                                <div className="box-inner d-flex align-items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9.238"
                                    height="15.647"
                                    viewBox="0 0 9.238 15.647"
                                  >
                                    <path
                                      id="Path_5536"
                                      data-name="Path 5536"
                                      d="M1122.005,1674.389l6.409,6.409-6.409,6.409"
                                      transform="translate(-1120.591 -1672.974)"
                                      fill="none"
                                      stroke="#212529"
                                      stroke-linecap="round"
                                      strokeWidth="2"
                                    />
                                  </svg>
                                  <p>
                                    <b>Exp</b> - {myjob?.acf?.experience}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex flex-wrap career-btn mt-4">
                                <div className="career-view">
                                  <Link to={`/careers/${myjob?.id}`}>
                                    View Details
                                  </Link>
                                </div>
                                <div className="career-aplly border-white">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#FormModal"
                                    onClick={handleClose}
                                    data-id={myjob?.title.rendered}
                                  >
                                    Apply Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CareersTwo;
