import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import BackArrow from "../../src/img/hireimg/back-arrow.svg";
import Benifits from "../../src/img/career/benifits.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { apiCall } from ".././authentication";
import CareerForm from "../Form/CareerFrom";
import Conloader from "../componentsheaderfooter/Conloader";
const CareersInnerone = (props) => {
  const [error, setError] = useState([]);
  const [isLoding, setIsLoding] = useState(true);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleClose = (e) => {
    const formElement = document.getElementById("careerSubmit");
    if (formElement) {
      formElement.reset();
      setError({});
      setIsCaptchaVerified(false);
    }
    let designation = e.target.dataset.id;
    const elements = document.querySelectorAll(".careers_heading");

    elements.forEach((element) => {
      element.innerText = designation;
    });
    const designationField = document.querySelector('[name="hidefield"]');

    if (designationField) {
      designationField.value = designation;
    }
  };

  const [jobDetail, setJobDetail] = useState([]);
  const [job, setJob] = useState([]);
  const { id } = useParams();
  const slug1 = `wp/v2/career/${id}`;
  const slug2 = `wp/v2/career`;
  const type = "get";
  const fetchJobs = async () => {
    try {
      const data = await apiCall(slug1, type);
      setJobDetail(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchJob = async () => {
    try {
      const data = await apiCall(slug2, type);
      setJob(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchJobs();
    fetchJob();
  }, [id]);

  useEffect(() => {
    if (jobDetail?.acf?.careers_inner_section[0]?.section_setting === true) {
      setIsLoding(false);
    }
  }, [jobDetail?.acf?.careers_inner_section[0]?.section_setting]);

  function formatAsyncDate(target) {
    if (target) {
      const isValidDate = !isNaN(Date.parse(target)); // Check if the input is a valid date string
      if (isValidDate) {
        const c_date = parseISO(target);
        const formatted = format(c_date, "d MMM yyyy");
        return formatted;
      }
    }
    // Handle the case where the input is not a valid date
    return "Invalid Date";
  }
  return (
    <>
      <div className="wordpress-section mb-5 pt-md-5 pb-md-5 pt-3">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-lg-9 mt-md-5">
              <div className="wordpress-content mb-1 mb-md-2">
                {isLoding ? (
                  <Conloader />
                ) : (
                  <>

                    <Link to="/careers">
                      <img src={BackArrow} className="img-fluid" alt="" />
                    </Link>
                    <div className="wordpress-text mt-3 mb-2">
                      <h2>{jobDetail?.title?.rendered}</h2>
                    </div>
                    <p>
                      <b>Exp</b> - {jobDetail?.acf?.experience}
                    </p>
                  </>
                )}
              </div>
              <div class="job-descroption-inner mt-5">
                {jobDetail?.acf?.careers_inner_section !== null && (
                  <h1>Error</h1>
                ) && (
                    <>
                      {isLoding ? (
                        <>
                          <Conloader />
                          <Conloader />
                          <Conloader />
                        </>
                      ) : (
                        <>
                          {jobDetail?.acf?.careers_inner_section[0]
                            ?.section_setting && (
                              <>
                                <h3>
                                  {
                                    jobDetail?.acf?.careers_inner_section[0]
                                      ?.job_title
                                  }
                                </h3>
                                <p
                                  class="mt-3"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      jobDetail?.acf?.careers_inner_section[0]
                                        ?.job_description,
                                  }}
                                ></p>
                              </>
                            )}

                          {jobDetail?.acf?.careers_inner_section[1]
                            ?.section_setting && (
                              <>
                                <h3>
                                  {
                                    jobDetail?.acf?.careers_inner_section[1]
                                      ?.description[0]?.description_title
                                  }
                                </h3>
                                <ul>
                                  {jobDetail?.acf?.careers_inner_section[1]
                                    ?.description &&
                                    jobDetail?.acf?.careers_inner_section[1]?.description[0]?.content?.map(
                                      (des, key) => (
                                        <li>
                                          <div class="job-arrow-icon d-flex align-items-baseline">
                                            <img src={Benifits} class="img-fluid" />
                                            <p>{des?.points}</p>
                                          </div>
                                        </li>
                                      )
                                    )}
                                </ul>
                                <h3>
                                  {
                                    jobDetail?.acf?.careers_inner_section[1]
                                      ?.description[1]?.description_title
                                  }
                                </h3>
                                {jobDetail?.acf?.careers_inner_section[1]
                                  ?.description &&
                                  jobDetail?.acf?.careers_inner_section[1]?.description[1]?.content?.map(
                                    (des, key) => (
                                      <ul>
                                        <li>
                                          <div class="job-arrow-icon d-flex align-items-baseline">
                                            <img src={Benifits} class="img-fluid" />
                                            <p>{des?.points}</p>
                                          </div>
                                        </li>
                                      </ul>
                                    )
                                  )}
                                <h3>
                                  {
                                    jobDetail?.acf?.careers_inner_section[1]
                                      ?.description[2]?.description_title
                                  }
                                </h3>
                                {jobDetail?.acf?.careers_inner_section[1]
                                  ?.description &&
                                  jobDetail?.acf?.careers_inner_section[1]?.description[2]?.content?.map(
                                    (des, key) => (
                                      <ul>
                                        <li>
                                          <div class="job-arrow-icon d-flex align-items-baseline">
                                            <img src={Benifits} class="img-fluid" />
                                            <p>{des?.points}</p>
                                          </div>
                                        </li>
                                      </ul>
                                    )
                                  )}
                              </>
                            )}
                          <div class="mt-4 career-aplly">
                            <Link
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#FormModal"
                              onClick={handleClose}
                              data-id={jobDetail?.title?.rendered}
                            >
                              Apply Now
                            </Link>
                          </div>
                        </>
                      )}
                    </>
                  )}
              </div>
            </div>
            <div className="col-lg-3 mt-0 mt-md-4 pt-5">
              <div className="other-job-inner mt-md-5 mb-2">
                {isLoding ? (
                  <>
                    <Conloader />
                    <Conloader />
                    <Conloader />
                  </>
                ) : (
                  <>
                    <h3>{formatAsyncDate(jobDetail?.date)}</h3>
                    <h3 className="mt-5 pt-4">Other Jobs</h3>
                    {job.length > 0 &&
                      job.map((our_job, key) => (
                        <ul>
                          <li>
                            <div class="other-job-content mt-3">
                              <p class="mb-0">
                                <b>{our_job?.title?.rendered}</b>
                              </p>
                              <p>{job[key]?.acf?.experience} </p>
                            </div>
                          </li>
                        </ul>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="FormModal"
        tabindex="-1"
        aria-labelledby="FormModal"
        aria-hidden="true"
      >
        <CareerForm />
      </div>
    </>
  );
};

export default CareersInnerone;
