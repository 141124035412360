import React, { useState, useEffect } from "react";
const Scrolldown = () => {
  const [scrollDirection, setScrollDirection] = useState("down");
  const [isAtTop, setIsAtTop] = useState(true);
  const [isAtBottom, setIsAtBottom] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const isTop = scrollPosition === 0;
      const isBottom = scrollPosition + windowHeight >= documentHeight - 200;

      setIsAtTop(isTop);
      setIsAtBottom(isBottom);

      if (isTop) {
        setScrollDirection("down");
      } else if (isBottom) {
        setScrollDirection("up");
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {isAtTop || isAtBottom ? (
        <div className={`scroll-bar ${scrollDirection === "down" ? "scroll-down" : "scroll-up"}`}>
          <div
            class={scrollDirection === "down" ? "scroll-down-icon" : "scroll-up-icon"}
          >
            {scrollDirection === "down" ? (
              <>
                <div className="scroll-down-text">
                  {isAtTop ? "Scroll down" : isAtBottom ? "Scroll up" : ""}
                </div>
                <div
                  class={scrollDirection === "down" ? "scroll-down-icon" : "scroll-up-icon"}
                >
                  <div class="scroll-down-icon">
                    <svg
                      fill="#cecece"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="11px"
                      height="36.189px"
                      viewBox="0 0 11 36.189"
                      enable-background="new 0 0 11 36.189"
                      space="preserve"
                    >
                      <g>
                        <line
                          class="stroke-1"
                          fill="none"
                          stroke="#cecece"
                          stroke-width="3.0556"
                          stroke-miterlimit="10"
                          x1="5.5"
                          y1="0"
                          x2="5.5"
                          y2="13.556"
                        ></line>
                        <g>
                          <line
                            class="stroke-2"
                            fill="none"
                            stroke="#cecece"
                            stroke-width="1.0316"
                            stroke-miterlimit="10"
                            stroke-dasharray="18.9727"
                            x1="5.5"
                            y1="36.189"
                            x2="5.5"
                            y2="17.216"
                          ></line>
                        </g>
                        <g>
                          <polygon
                            class="stroke-3"
                            points="0,11.946 5.5,21.472 11,11.946 		"
                          ></polygon>
                        </g>
                      </g>
                    </svg>

                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  class={scrollDirection === "down" ? "scroll-down-icon" : "scroll-up-icon"}
                >
                  <div class="scroll-down-icon">
                    <svg
                      fill="#cecece"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="11px"
                      height="36.189px"
                      viewBox="0 0 11 36.189"
                      enable-background="new 0 0 11 36.189"
                      space="preserve"
                    >
                      <g>
                        <line
                          class="stroke-1"
                          fill="none"
                          stroke="#cecece"
                          stroke-width="3.0556"
                          stroke-miterlimit="10"
                          x1="5.5"
                          y1="36.189"
                          x2="5.5"
                          y2="22.633"
                        ></line>
                        <g>
                          <line
                            class="stroke-2"
                            fill="none"
                            stroke="#cecece"
                            stroke-width="1.0316"
                            stroke-miterlimit="10"
                            stroke-dasharray="18.9727"
                            x1="5.5"
                            y1="0"
                            x2="5.5"
                            y2="18.973"
                          ></line>
                        </g>
                        <g>
                          <polygon
                            class="stroke-3"
                            points="0,24.243 5.5,14.717 11,24.243"
                          ></polygon>
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="scroll-down-text">
                  {isAtTop ? "Scroll down" : isAtBottom ? "Scroll up" : ""}
                </div>

              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default Scrolldown;
