import React, { useState, useEffect } from "react";
import WhitepaperDetailone from "./whitepaperdetailpage/WhitepaperDetailOne";
import WhitepaperDetailtwo from "./whitepaperdetailpage/WhitepaperDetailTwo";
import CommonCta from "./CommonCta";
import Loader from "./Loader";
import "../src/BlogDetail.css";
import { useParams } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import { apiCall } from "./authentication";

const WhitepaperDetail = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState([]);
  const [blogdata, setBlogData] = useState([]);
  const [load, isLoad] = useState(true);
  const [yoastData, setYoastData] = useState({});

  let finalreacturl;
  const slug1 = `wp/v2/whitepaper/?slug=${slug}&_embed`;
  const slug2 = `relatedarticles/articles?_embed`;
  const type = "get";
  const fetchPost = async () => {
    isLoad(true);
    try {
      const data = await apiCall(slug1, type);
      setBlogPost(data);
      setYoastData(data[0].yoast_head_json);

      isLoad(false);
    } catch (error) {
      console.error("Error fetching Blog:", error);
    }
  };

  const fetchBlog = async () => {
    try {
      const data = await apiCall(slug2, type);
      setBlogData(data);
    } catch (error) {
      console.error("Error fetching Blog:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // Assuming 'id' is defined elsewhere in your component
    fetchPost();
    fetchBlog();
  }, [slug]);

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }
  return (
    <>
      {load ? (
        <></>
      ) : (
        <>
          <HelmetProvider>
            <Helmet>
              <title>{yoastData.title}</title>
              <meta name="description" content={yoastData.description} />
              <link rel="canonical" hreflang="en" href={finalreacturl} />
              <meta
                property="og:locale"
                content="en_US"
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:type"
                content="article"
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:title"
                content={yoastData.og_title}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:description"
                content={yoastData.og_description}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:url"
                content={finalreacturl}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:site_name"
                content={yoastData.og_site_name}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="article:modified_time"
                content={yoastData.article_modified_time}
                className="yoast-seo-meta-tag"
              />
              <meta
                property="og:image"
                content={yoastData.url}
                className="yoast-seo-meta-tag"
              />
              {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
              {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
              <meta
                name="twitter:label1"
                content="Est. reading time"
                className="yoast-seo-meta-tag"
              />
              <meta
                name="twitter:data1"
                content="1 minute"
                className="yoast-seo-meta-tag"
              />
            </Helmet>
          </HelmetProvider>
          <WhitepaperDetailone data={blogPost} />
          <WhitepaperDetailtwo data={blogPost} />
          {/* <WhitepaperDetailthree article={blogdata} /> */}
          <CommonCta />
        </>
      )}
    </>
  );
};

export default WhitepaperDetail;
