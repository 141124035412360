import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../Loader";
import Slider from "react-slick";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";

const HireInnerthirteen = (props) => {
  const [hireinner13, setHireinner13] = useState([]);
  const [hireinner13data, setHireinner13Data] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let selectedIds = [];
  let stringIds = "";
  if (props.hire13inner && Array.isArray(props.hire13inner.partners_logo)) {
    selectedIds = props.hire13inner.partners_logo?.map(
      (section9data) => section9data.ID
    );
    stringIds = selectedIds.toString();
  }
  const slug1 = `wp/v2/partners?include=${stringIds}&_embed&per_page=15`;
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line no-lone-blocks
    {
      setHireinner13(props.hire13inner);
      const fetchPost = async () => {
        try {
          const dataArray = await apiCall(slug1, type);
          setHireinner13Data(dataArray);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching portflioPost:", error);
        }
      };
      fetchPost();
    }
  }, []);
  const Options = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false,
    autoplay: true,
    cssEase: "ease",
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
      <div>
        <div>
          {hireinner13?.section_settings === true ? (
            <div className="home-banner section-spacing graybg">
              <div className="">
                <div className="flex-c">
                  <div className="lion-box w50 w-800-100 fadeIn">
                    <div id="lionCanvasDark" className="darkAnim">
                      <div className="initDot"></div>
                    </div>
                    <div id="particles-js"></div>
                  </div>
                  <div className="owl-main">
                    <div className="owl-content container pt-0 container">
                      {isLoading ? (
                        <Conloader />
                      ) : (
                        <>
                          <h2>{hireinner13?.our_partner_text}</h2>
                        </>
                      )}
                    </div>
                    <Slider className="logo-slider mt-5" {...Options}>
                      {hireinner13data &&
                        hireinner13data.map((response, index) => {
                          return (
                            <>
                              {isLoading ? (
                                <Conloader />
                              ) : (
                                <>
                                  <HireInnerSlide
                                    img={
                                      response.yoast_head_json?.og_image?.[0]?.url
                                    }
                                  />
                                </>
                              )}
                            </>
                          );
                        })}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div >
    </>
  );
};

function HireInnerSlide(props) {
  return (
    <>
      <div className="item">
        <div className="white-box">
          <img src={props.img} alt="" className="img-fuiud" />
        </div>
      </div>
    </>
  );
}

export default HireInnerthirteen;
