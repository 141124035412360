import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Hiresectionone from "./hirepage/Hiresectionone";
import Hiresectiontwo from "./hirepage/Hiresectiontwo";
import Hiresectionthree from "./hirepage/Hiresectionthree";
import Hiresectionfour from "./hirepage/Hiresectionfour";
import Hiresectionfive from "./hirepage/Hiresectionfive";
import Hiresectionsix from "./hirepage/Hiresectionsix";
import Hiresectionseven from "./hirepage/Hiresectionseven";
import Hiresectioneight from "./hirepage/Hiresectioneight";
import Hiresectionnine from "./hirepage/Hiresectionnine";
import Homesectioneight from "./homepage/Homesectioneight";
import Hiresectionten from "./hirepage/Hiresectionten";
import Loader from "./Loader";
import ReCAPTCHA from "react-google-recaptcha";
import { apiCall, imageUpdate } from "./authentication";
import "../src/Hire.css";
import { useParams } from "react-router-dom";
import CommonCta from "./CommonCta";
import Form from "./Form/Form";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const HireDev = () => {
    const [error, setError] = useState([]);
    const { slug } = useParams();
    const [users4, setUsers4] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hiresection1, setHiresection1] = useState([]);
    const [hiresection2, setHiresection2] = useState([]);
    const [hiresection3, setHiresection3] = useState([]);
    const [hiresection4, setHiresection4] = useState([]);
    const [hiresection5, setHiresection5] = useState([]);
    const [hiresection6, setHiresection6] = useState([]);
    const [hiresection7, setHiresection7] = useState([]);
    const [hiresection8, setHiresection8] = useState([]);
    const [hiresection9, setHiresection9] = useState([]);
    const [hiresection10, setHiresection10] = useState([]);
    const [hiresection11, setHiresection11] = useState([]);
    const [datapartner, setDatapartner] = useState([]);
    const [yoastData, setYoastData] = useState({});
    const [hireForm, setHireForm] = useState([]);
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    console.log("users4", users4);
    let finalreacturl;

    const handleClose = () => {
        setCurrentStep(0);
        const formElement = document.getElementById("formCareer");
        const numericInputs = document.getElementsByClassName("numericInput");

        for (let i = 0; i < numericInputs.length; i++) {
            numericInputs[i].addEventListener("keypress", checkInputValue);
        }
        if (formElement) {
            formElement.reset();
            setError({});
            setIsCaptchaVerified(false);
        }
    };

    const checkInputValue = (e) => {
        const input = document.querySelector(".form-control.numericInput");

        // Check if input exists and the value length is already 10
        if (input && input.value.length >= 10 && e.keyCode !== 8 /* Backspace */) {
            e.preventDefault();
            return;
        }
        // Get the entered key
        const key = String.fromCharCode(e.keyCode);

        // Check if the entered key is a number
        const isNumber = /[0-9]/.test(key);

        // Prevent the input if the entered key is not a number
        if (!isNumber) {
            e.preventDefault();
        }
    };
    const slug1 = `wp/v2/pages?slug=${slug}&acf_format=standard&_embed`;
    const type = "get";
    useEffect(() => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        const fetchData = async () => {
            try {
                const data = await apiCall(slug1, type);
                console.log("DAt 123456789", data);
                setUsers4(data);
                setYoastData(data[0].yoast_head_json);

                data[0].acf.common_section.forEach((element) => {
                    if (element.acf_fc_layout == "banner_section") {
                        setHiresection1(element);
                    }

                    if (element.txt_img_sec_name == "text_with_image_section_1") {
                        setHiresection2(element);
                    }

                    if (element.acf_fc_layout == "our_partner_section") {
                        setHiresection6(element);
                    }

                    if (element.txt_img_sec_name == "text_with_image_section_2") {
                        setHiresection7(element);
                    }

                    if (element.acf_fc_layout == "article_section") {
                        setHiresection8(element);
                    }

                    if (element.acf_fc_layout == "cta_section") {
                        setHiresection9(element);
                    }

                    if (element.acf_fc_layout == "testimonials_section") {
                        setHiresection10(element);
                    }
                });
                data[0].acf.hire_sections.forEach((element) => {
                    if (element.acf_fc_layout == "expert_section") {
                        setHiresection3(element);
                    }

                    if (element.acf_fc_layout == "mobile_dev_services_section") {
                        setHiresection4(element);
                    }

                    if (element.acf_fc_layout == "hire_mobile_app_dev_section") {
                        setHiresection5(element);
                    }

                    if (element.acf_fc_layout == "hiring_process") {
                        setHiresection11(element);
                    }
                });
                setIsLoading(false);
                console.log("new dev:", isLoading);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [slug]);


    if (yoastData.og_url !== undefined) {
        const wpurl = yoastData.og_url.toString()
        const newurl = wpurl.replace('wpatlas', 'www');
        const newurl1 = newurl.replace('.com/', '.com/hire-service/');
        finalreacturl = newurl1.slice(0, newurl1.lastIndexOf('/'));
        console.log("finalreacturl", finalreacturl);
    }

    setTimeout(() => {
        imageUpdate();
    }, 1000);
    return (
        <>
            {isLoading ? (
                <div className="container">
                    <div className="row height-animation align-items-center">
                        <div className="col-md-6">
                            <Conloader />
                        </div>
                        <div className="col-md-6">
                            <Imgloader />
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <Hiresectionone handleClose={handleClose} hire1data={hiresection1} />
                    <Hiresectiontwo hire2data={hiresection2} />
                    <Hiresectionthree hire3data={hiresection3} />
                    <Hiresectionfour hire4data={hiresection4} />
                    <Hiresectionfive handleClose={handleClose} hire5data={hiresection5} />
                    <Hiresectionsix hire6data={hiresection11} />
                    <Hiresectionseven hire7data={hiresection6} />
                    <Hiresectionten hire10data={hiresection10} />
                    <Hiresectioneight hire8data={hiresection8} />
                    <CommonCta />
                    <div
                        className="modal fade"
                        id="FormModal"
                        tabindex="-1"
                        aria-labelledby="FormModal"
                        aria-hidden="true"
                    >
                        <Form />
                    </div>
                </div>
            )}
        </>
    );
};

export default HireDev;