import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { apiCall } from "./authentication";
import Conloader from "./componentsheaderfooter/Conloader";
function Terms() {
  const [privacydata, setPrivacydata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const slug1 = "wp/v2/pages/?slug=terms-of-service";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    // Step 1: Fetch data from the initial REST API
    const fetchTerm = async () => {
      try {
        const data = await apiCall(slug1, type);
        setPrivacydata(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchTerm();
  }, []); // Empty dependency array ensures the effect runs once
  return (
    <>
      <div className="pt-md-5 pb-md-5 pt-5 pb-3 home-banner">
        <div className="container pt-5 pb-5">
          {isLoading ? (
            <>
              <Conloader />
              <Conloader />
              <Conloader />
            </>
          ) : (
            <div
              className="row gx-md-5 pt-md-5 pb-md-5 align-items-stretch"
              id="terms"
            >
              <h1 className="fw-bold page-title">
                {privacydata[0]?.title.rendered}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: privacydata[0]?.content.rendered,
                }}
              ></p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Terms;
