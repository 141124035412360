import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserFace from "../../src/img/aboutimg/user-face.png";
import { apiCall } from "../authentication";

const EmploySlider = ({ sliders, selectedIDreview }) => {
  const [employedata, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const slug = `wp/v2/employee-reviews?per_page=20&page=1&include=${selectedIDreview}&acf_format=standard&_embed`;
  const type = "get";
  const fetchData = async () => {
    try {
      const data = await apiCall(slug, type);
      setEmployeeData(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);
  return (
    <>
      <div>
        <Slider {...sliders}>
          {employedata &&
            employedata.map((response, index) => {
              return (
                <>
                  <EmploySlid
                    img={
                      response._embedded["wp:featuredmedia"] &&
                        response._embedded["wp:featuredmedia"][0]
                        ? response._embedded["wp:featuredmedia"][0].source_url
                        : `${UserFace}`
                    }
                    name={response.title.rendered}
                    roll={response.acf.designation}
                    detail={response.content.rendered}
                  />
                </>
              );
            })}
        </Slider>
      </div>
    </>
  );
};

function EmploySlid(props) {
  return (
    <div className="slider_main dot-animation">
      <div className="slider_head">
        <div>
          <div className="slider_heading">
            <figure>
              <img src={props.img} alt="" />
            </figure>
            <div className="heading">
              <h4>{props.name}</h4>
              <p>{props.roll}</p>
            </div>
          </div>
          <div className="slider_body">
            <p dangerouslySetInnerHTML={{ __html: props.detail }}></p>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end dot-flex-testi">
            <div className="dot-testi light-green-testi"></div>
            <div className="dot-testi green-testi"></div>
            <div className="dot-testi blue-testi"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmploySlider;
