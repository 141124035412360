import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VacuumSlide from "../../src/img/portfolio/vacuum-slide.png";

const PortfolioSlider = ({ sliders, imagePath }) => {
  return (
    <Slider {...sliders}>
      {imagePath &&
        imagePath.length > 0 &&
        imagePath.map((res, index) => (
          <EmploySlid img={res.case_study_inner_slider_image} />
        ))}
    </Slider>
  );
};

function EmploySlid({ img }) {
  return (
    <div className="">
      <div className="">
        <div className="">
          <figure>
            <img src={img} alt="" />
          </figure>
        </div>
      </div>
    </div>
  );
}

export default PortfolioSlider;
