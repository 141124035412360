import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Servicesectionfour = (props) => {
  const { services4data } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".time-material",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation2"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    if (services4data.section_setting === true) {
      setIsLoading(false);
    }

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <>
      {services4data.section_setting === true ? (
        <section className="time-material section-spacing">
          <div className="container">
            <div className="accordion commonanimation2" id="accordionExample">
              <div className="accordion-item time-tebimation row gap-0 justify-content-between">
                {services4data &&
                  services4data?.service_tabs.map((data, index) => {
                    return (
                      <>
                        {isLoading ? (
                          <Conloader />
                        ) : (
                          <h2
                            className="accordion-header col-md-4 col-12 p-0"
                            id={`headingOne${index}`}
                            key={index}
                          >
                            <img
                              src={
                                index === 0
                                  ? data?.service_tab_icon?.url
                                  : index === 1
                                    ? data?.service_tab_icon?.url
                                    : data?.service_tab_icon?.url
                              }
                              alt=""
                            />
                            <button
                              className={`accordion-button ${index === 0 ? "collapsed" : ""
                                }`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${index}`}
                              aria-expanded={index === 0 ? true : false}
                              aria-controls={`#collapseOne${index}`}
                            >
                              {data?.service_tab_title}
                            </button>
                          </h2>
                        )}
                      </>
                    );
                  })}
              </div>
              <div className="accordion-item">
                {services4data &&
                  services4data?.service_tabs.map((data, index) => (
                    <div
                      id={`collapseOne${index}`}
                      className={`accordion-collapse ${index === 0 ? "collapse show" : "collapse"
                        }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                      key={index}
                    >
                      <div className="accordion-body">
                        <div className="row align-items-center gx-md-5">
                          <div className="col-md-6 pt-md-2 pt-xxl-5">
                            {isLoading ? (
                              <Imgloader />
                            ) : (
                              <div className="time-content ">
                                <img
                                  src={data?.service_tab_image?.url}
                                  className="img-fluid doodles-image"
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 pt-md-2 pt-xxl-5">
                            {isLoading ? (
                              <Conloader />
                            ) : (
                              <div className="time-text">
                                <h2>{data?.service_tab_inner__title}</h2>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: data?.service_tab_inner_content,
                                  }}
                                ></p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Servicesectionfour;
