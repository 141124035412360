import React, { useEffect, useState } from "react";
import BackArrow from "../../src/img/hireimg/back-arrow.svg";
import AtlasIcon1 from "../../src/img/servicesimg/atlas-icon.svg";
import { Link } from "react-router-dom";
import image1 from "../img/placeholder-1-1.webp";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const PressReleaseDetailone = ({ data }) => {
  const [load, isLoad] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      isLoad(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((link, index) => (
          <div key={index} className="pt-md-5 pt-3 pb-3">
            <div className="container pt-5">
              <div className="row gx-md-5 pt-5 align-items-center">
                <div className="col-12">
                  {load ? (
                    <Conloader />
                  ) : (
                    <>
                      <div className="blog-content">
                        <Link to="/press-release">
                          <img src={BackArrow} alt="" className="img-fluid" />
                        </Link>
                        <h1>{link.title.rendered}</h1>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="author-content d-flex align-items-center mt-4">
                          <div className="author-icon">
                            <img src={AtlasIcon1} alt="" className="img-fluid" />
                          </div>
                          <div className="author-text">
                            <h3>Author</h3>
                            <p>Atlas Softweb</p>
                          </div>
                          <div className="author-text">
                            <p>Published</p>
                            <h3>July 25, 2023</h3>
                          </div>
                        </div>
                        <div className="d-flex dot-flex align-items-end">
                          <div className="dot-sec"></div>
                          <div className="dot-sec1"></div>
                          <div className="dot-sec2"></div>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="blog-image mt-4">
                    {load ? (
                      <Imgloader />
                    ) : (
                      <>
                        {link?._embedded &&
                          link?._embedded["wp:featuredmedia"] &&
                          link?._embedded["wp:featuredmedia"][0].source_url ? (
                          <img
                            src={link?._embedded["wp:featuredmedia"][0].source_url}
                            alt=""
                            className="border-radius"
                          />
                        ) : (
                          <img
                            src={image1}
                            alt="placeholderimage"
                            className="border-radius"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div >
            </div >
          </div >
        ))}
    </>
  );
};

export default PressReleaseDetailone;
