import React, { useEffect, useState, useRef } from "react";
import SliderService1 from "./SliderServices1";
import SliderService2 from "./SliderServices2";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const Servicesectiontwo = (props) => {
  const [services2data, setservices2data] = useState(props.inner);
  const services1Ref = useRef(null);
  const services2Ref = useRef(null);
  const [slidersInitialized, setSlidersInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    var controllerMid = new ScrollMagic.Controller();

    // First ScrollMagic Scene
    const scene1 = new ScrollMagic.Scene({
      triggerElement: ".servicesanimationstart", // Replace with your trigger element
      triggerHook: 0.8, // Trigger the animation when the element is in the middle of the viewport
      duration: "50%", // Length of the animation trigger
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation1"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene

    scene1.setTween(animationTimeline);
    if (slidersInitialized) {
      scene1.addTo(controllerMid);
    }

    if (props?.inner?.section_setting === true) {
      setIsLoading(false);
    }

    return () => {
      controllerMid.destroy();
    };
  }, [slidersInitialized]);

  const slider1Services = {
    asNavFor: services2Ref.current,
    ref: services1Ref,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    dots: false,
    arrows: true,
    speed: 500,
    draggable: false,
    swipeToSlide: false,
    touchMove: false,
    prevArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.088"
        height="63.932"
        viewBox="0 0 34.088 63.932"
      >
        <path
          id="Path_5406"
          data-name="Path 5406"
          d="M249.94,7979.085l-30.906,30.906L249.94,8040.9"
          transform="translate(-216.913 -7978.025)"
          fill="none"
          stroke="#A2BA5F"
          strokeWidth="3"
        />
      </svg>
    ),
    nextArrow: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.088"
        height="63.933"
        viewBox="0 0 34.088 63.933"
      >
        <path
          id="Path_5407"
          data-name="Path 5407"
          d="M249.94,7979.085l-30.906,30.906L249.94,8040.9"
          transform="translate(251 8041.957) rotate(180)"
          fill="none"
          stroke="#A2BA5F"
          strokeWidth="3"
        />
      </svg>
    ),

    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: () => {
      // Set the slidersInitialized state to true
      setSlidersInitialized(true);
    },
  };

  const slider2Services = {
    asNavFor: services1Ref.current,
    ref: services2Ref,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    draggable: false,
    swipeToSlide: false,
    touchMove: false,
    afterChange: () => {
      // Set the slidersInitialized state to true
      setSlidersInitialized(true);
    },
  };
  return (
    <>
      {props?.inner?.section_setting === true ? (
        <div className="section-spacing graylitebg" id="technologies_slider">
          <div className="container" id="Services_slide">
            {/* <div className="servicesanimationstart"></div> */}
            <div className="row">
              <div className="col-12 mb-4">
                {/* commonanimation1 */}
                {isLoading ? (
                  <Conloader />
                ) : (
                  <h2 className="page-title fw-bold  mb-2 text-center">
                    {props?.inner?.service_list_main_title}
                  </h2>
                )}
              </div>
              <div className="col-12" id="services_slide_inner">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <SliderService2
                    sliderData={props.inner}
                    settings={slider2Services}
                    SliderServices1={services1Ref}
                  />
                )}
                {isLoading ? (
                  <Conloader />
                ) : (
                  <SliderService1
                    sliderData={props.inner}
                    settings={slider1Services}
                    SliderServices2={services2Ref}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Servicesectiontwo;
