import React, { useEffect, useState } from "react";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import { apiCall } from "../authentication";
import Imgloader from "../componentsheaderfooter/Imgloader";
import Conloader from "../componentsheaderfooter/Conloader";
const Aboutsectionfive = (props) => {
  const { about5data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [teampostdata, setTeampostdata] = useState([]);

  const slug = `wp/v2/our-team/${about5data.select_our_team}/?_embed`;
  const type = "get";
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiCall(slug, type);
        setTeampostdata(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    ScrollMagicPluginGsap(ScrollMagic, TweenMax);

    const controller = new ScrollMagic.Controller();

    const scene = new ScrollMagic.Scene({
      triggerElement: ".teamsection",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });

    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".commonanimation1"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );

    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  return (
    <>
      {about5data.section_settings === true ? (
        <div className="section-spacing px-3 teamsection">
          <div className="container aboutfive">            
            <div className="row commonanimation1">
              <div className="col-12">
              {isLoading ? (
              <Conloader />
            ) : (
              <h2 className="page-title fw-bold mb-md-5 mb-4">
                {about5data.our_team_title}
              </h2>
            )}
              </div>
              <div className="col-lg-5 col-12 mb-md-0 mb-3 img-section">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <>
                    <figure>
                      <img
                        className="img-fluid w-100"
                        src={
                          teampostdata?._embedded?.["wp:featuredmedia"]?.[0]
                            ?.source_url
                        }
                        alt=""
                      />
                    </figure>
                    <h2>{teampostdata?.title?.rendered}</h2>
                    <p>{about5data?.excerpt?.rendered}Founder</p>
                  </>
                )}
              </div>
              <div className="col-lg-6 col-12">
                {isLoading ? (
                  <>
                    <Conloader />
                  </>
                ) : (
                  <p
                    className="atlas-paragraph"
                    dangerouslySetInnerHTML={{
                      __html: teampostdata?.content?.rendered,
                    }}
                  ></p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Aboutsectionfive;
