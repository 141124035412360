import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Service from "./Service";
import Hire from "./Hire";
import HireInner from "./HireInner";
import Contactus from "./Contactus";
import BlogDetail from "./BlogDetail";
import BlogList from "./BlogList";
import ClientLogo from "./ClientLogo";
import Testimonial from "./Testimonial";
import NewsRooms from "./NewsRooms";
import Careers from "./Careers";
import CareersInner from "./CareersInner";
import PortfolioInner from "./PortfolioInner";
import Portfolio from "./Portfolio";
import ServicesDetail from "./ServicesDetail";
import Header from "./componentsheaderfooter/Header";
import Footer from "./componentsheaderfooter/Footer";
import ArticleList from "./ArticleList";
import ArticleDetail from "./ArticleDetail";
import CaseStudyInner from "./CaseStudyInner";
import Rightside from "./componentsheaderfooter/Rightside";
import DotRing from "./componentsheaderfooter/DotRing";
import BackToTopButton from "./BackToTopButton";
import InfographicsList from "./InfographicsList";
import InfographicsDetail from "./InfographicsDetail";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import WhitepaperList from "./WhitepaperList";
import WhitepaperDetail from "./WhitepaperDetail";
import PressReleaseList from "./PressReleaseList";
import PressReleaseDetail from "./PressReleaseDetail";
import NotFoundPage from "./NotFoundPage";
import Thankyou from "./Form/Thankyou";
import ThankyouCareers from "./Form/ThankyouCareer";
import axios from "axios";
import Brand from "./Brand";
import HireDev from "./HireDev";

let authorizationToken = localStorage.getItem("jwtToken");
export const handleLogin = async () => {
  try {
    const response = await axios.post(
      "https://wpatlas.atlassoftweb.com/atlasapi/jwt-auth/v1/token",
      {
        username: "atlasdevelopstag",
        password: "DEA98R3kpTIaF^%?",
      }
    );
    const token = response.data.data.token;

    localStorage.setItem("jwtToken", token);
  } catch (error) {
    console.error("Login failed:", error);
  }
};

if (!authorizationToken) {
  await handleLogin();
}
function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Header />
          <DotRing />
          <BackToTopButton />
          <div className="content-div">
            <Routes>
              <Route exact path="/thankyou" element={<Thankyou />}></Route>
              <Route
                exact
                path="/thankyou-careers"
                element={<ThankyouCareers />}
              ></Route>

              <Route exact path="/" element={<Home />}></Route>
              <Route exact path="/about-us" element={<About />}></Route>
              <Route exact path="/contact" element={<Contactus />}></Route>
              <Route exact path="/our-services" element={<Service />}></Route>
              <Route
                exact
                path="/our-services/:slug"
                element={<ServicesDetail />}
              ></Route>
              <Route
                exact
                path="/hire-developers/:slug"
                element={<Hire />}
              ></Route>
              <Route
                exact
                path="/hire-service/:slug"
                element={<HireDev />}
              ></Route>
              <Route exact path="/hire/:slug" element={<HireInner />}></Route>
              <Route
                exact
                path="/blog/:slug"
                element={<BlogDetail />}
              ></Route>
              <Route exact path="/blog" element={<BlogList />}></Route>
              <Route
                exact
                path="/infographics"
                element={<InfographicsList />}
              ></Route>
              <Route exact path="/articles" element={<ArticleList />}></Route>
              <Route
                exact
                path="/articles/:slug"
                element={<ArticleDetail />}
              ></Route>

              <Route
                exact
                path="/our-clients"
                element={<ClientLogo />}
              ></Route>
              <Route
                exact
                path="/testimonial"
                element={<Testimonial />}
              ></Route>
              <Route exact path="/newsrooms" element={<NewsRooms />}></Route>
              <Route exact path="/careers" element={<Careers />}></Route>
              <Route
                exact
                path="/careers/:id"
                element={<CareersInner />}
              ></Route>
              <Route
                exact
                path="/whitepaper/:slug"
                element={<WhitepaperDetail />}
              ></Route>
              <Route
                exact
                path="/press-release"
                element={<PressReleaseList />}
              ></Route>
              <Route
                exact
                path="/press-release/:slug"
                element={<PressReleaseDetail />}
              ></Route>
              <Route
                exact
                path="/infographics/:slug"
                element={<InfographicsDetail />}
              ></Route>
              <Route
                exact
                path="/whitepaper"
                element={<WhitepaperList />}
              ></Route>
              <Route
                exact
                path="/work/:id"
                element={<PortfolioInner />}
              ></Route>
              <Route exact path="/work" element={<Portfolio />}></Route>
              <Route
                exact
                path="/case-studies"
                element={<Portfolio />}
              ></Route>
              <Route
                exact
                path="/case-studies/:id"
                element={<CaseStudyInner />}
              ></Route>
              <Route
                exact
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              ></Route>
              <Route
                exact
                path="/terms-of-service"
                element={<Terms />}
              ></Route>
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/brand-journey" element={<Brand />} />
            </Routes>
          </div>
          <Footer />
          <Rightside />
        </div>
      </Router>
    </>
  );
}

export default App;