import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import image from "../img/placeholder-1-1.webp";
import { apiCall } from "../authentication";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

const InfographicsListone = () => {
  const [blog, setBlog] = useState([]);
  const [innerArticle, setInnerArticle] = useState([]);
  const [loading, setLoading] = useState(true);
  const slug1 = `wp/v2/infographics?per_page=100&_embed`;
  const slug2 = "wp/v2/pages?slug=infographics";
  const type = "get";
  const fetchArticle = async () => {
    setLoading(true);
    try {
      const data = await apiCall(slug1, type);
      setInnerArticle(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching article:", error);
    }
  };

  const fetchBlog = async () => {
    try {
      const data = await apiCall(slug2, type);
      setBlog(data[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function stripHTMLTags(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlog();
    fetchArticle();
  }, []);

  const renderedContent = blog?.content?.rendered;

  const renderContent = () => {
    if (!renderedContent) {
      return null; // Handle cases where the content is empty or not available
    }
    const contentArray = renderedContent.split(/(<h3[^>]*>.*?<\/h3>)/);
    return (
      <>
        {loading ? (
          <Conloader />
        ) : (
          <>
            {contentArray.map((item, index) =>
              item.includes("<h3") ? (
                <h3
                  key={index}
                  className="wp-block-heading"
                  dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(item),
                  }}
                ></h3>
              ) : (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{
                    __html: stripHTMLTags(item),
                  }}
                ></p>
              )
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="pt-5 pb-md-5 pb-3">
        <div className="container pt-5 pb-5">
          <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
            <div className="col-md-8 col-12">
              {loading ? (
                <Conloader />
              ) : (
                <>
                  <p className="list-link">{blog?.title?.rendered}</p>
                  {renderContent()}
                </>
              )}
            </div>
          </div>
          <div className="row justify-content-start pt-md-5 gy-md-5">
            {innerArticle && innerArticle.length > 0 ? (
              loading ? (
                <Imgloader />
              ) : (
                innerArticle.map((item) => (
                  <BlogArticles data={item} image1={image} />
                ))
              )
            ) : (
              <p>No matching articles found.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function BlogArticles({ data, image1 }) {
  const [loading, setLoading] = useState(true);
  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-US", options);
  }
  useEffect(() => {
    // Simulate loading delay
    const delay = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(delay);
  }, []);
  return (
    <div className="artical-card dot-animation">
      <div className="artical-card-img">
        {loading ? (
          <Conloader />
        ) : (
          <>
            <Link to={`/infographics/${data?.slug}`}>
              {data?._embedded &&
                data?._embedded["wp:featuredmedia"] &&
                data?._embedded["wp:featuredmedia"][0].source_url ? (
                <img
                  src={data?._embedded["wp:featuredmedia"][0].source_url}
                  alt=""
                />
              ) : (
                <img src={image1} alt="placeholderimage" />
              )}
            </Link>
          </>
        )}
      </div>
      <div className="row justify-content-between align-items-center artical-card-head">
        <div className="col">
          {loading ? (
            <Conloader />
          ) : (
            <>
              <p>
                {/* Web Development */}
                {data?._embedded?.["wp:term"]?.[0]?.[0]?.name}
              </p>
            </>
          )}
        </div>
        <div className="col">
          {loading ? (
            <Conloader />
          ) : (
            <>
              <div className="d-flex justify-content-end dot-flex-testi">
                <div className="dot-testi light-green-testi"></div>
                <div className="dot-testi green-testi"></div>
                <div className="dot-testi blue-testi"></div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="artical-card-title">
        {loading ? (
          <Conloader />
        ) : (
          <>
            <h3>
              {/* Cost to Develop a Website in 2023: The Complete Guide */}
              {data?.title?.rendered}
            </h3>
            <p className="artical-card-date">
              {/* July 26, 2023 */}
              {formatDate(data?.date)}
            </p>
          </>
        )}
      </div>
    </div >
  );
}

export default InfographicsListone;
