import React, { useEffect, useState } from "react";
import BlogListone from "./bloglistpage/BlogListone";
import "../src/BlogList.css";
import CommonCta from "./CommonCta";
import Conloader from "./componentsheaderfooter/Conloader";

const BlogList = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="container">
          <div className="row height-animation align-items-center">
            <div className="col-md-8">
              <Conloader />
            </div>
          </div>
        </div>
      ) : (
        <>
          <BlogListone />
          <CommonCta />
        </>
      )}
    </>
  );
};

export default BlogList;
