import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Contactone from "./contactpage/Contactone";
import Contacttwo from "./contactpage/Contacttwo";
import Contactthree from "./contactpage/Contactthree";
import Loader from "./Loader";
import { apiCall, imageUpdate } from "./authentication";
import "../src/Contactus.css";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const Contactus = () => {
  const [users6, setUsers6] = useState([]);
  const [contactsection1, setContactsection1] = useState([]);
  const [contactsection1inner, setContactsection1inner] = useState([]);
  const [contactsection2, setContactsection2] = useState([]);
  const [contactsection3, setContactsection3] = useState([]);
  const [contactsection2inner, setContactsection2inner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [yoastData, setYoastData] = useState({});

  let finalreacturl;

  const slug1 = "wp/v2/pages?slug=contact";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchArticle = async () => {
      try {
        const data = await apiCall(slug1, type);
        setUsers6(data);
        setContactsection1(data[0].acf.contact_us_section[0]);
        setContactsection1inner(data[0].acf.contact_us_section[0]);
        setContactsection2(data[0].acf.contact_us_section[1]);
        setContactsection2inner(data[0].acf.contact_us_section[2]);
        setContactsection3(data[0].acf.contact_us_section[3]);
        setYoastData(data[0].yoast_head_json);
        setIsLoading(false);
        data[0].acf.contact_us_section.forEach((element) => {
          if (element.acf_fc_layout === "contact_form_section") {
            setContactsection1(element);
          }
        });
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };
    fetchArticle();
  }, []); // Empty dependency array ensures the effect runs once

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }
  setTimeout(() => {
    imageUpdate();
  }, 1000);
  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Conloader />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>
              <HelmetProvider>
                <Helmet>
                  <title>{yoastData.title}</title>
                  <meta name="description" content={yoastData.description} />
                  <link rel="canonical" hreflang="en" href={finalreacturl} />
                  <meta
                    property="og:locale"
                    content="en_US"
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="og:type"
                    content="article"
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="og:title"
                    content={yoastData.og_title}
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="og:description"
                    content={yoastData.og_description}
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="og:url"
                    content={finalreacturl}
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="og:site_name"
                    content={yoastData.og_site_name}
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="article:modified_time"
                    content={yoastData.article_modified_time}
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    property="og:image"
                    content={yoastData.url}
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    name="twitter:label1"
                    content="Est. reading time"
                    className="yoast-seo-meta-tag"
                  />
                  <meta
                    name="twitter:data1"
                    content="1 minute"
                    className="yoast-seo-meta-tag"
                  />
                </Helmet>
              </HelmetProvider>
              <Contactone contact1data={contactsection1} contact1datainner={contactsection1inner} />
              <Contacttwo contact2data={contactsection2} contact2datainner={contactsection2inner} />
              <Contactthree contact3data={contactsection3} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Contactus;
