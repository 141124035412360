import React, { useEffect, useState } from "react";
import Imgloader from "../componentsheaderfooter/Imgloader";

const ClientLogotwo = (props) => {
  const { client2logo } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (client2logo.section_setting === true) {
      setIsLoading(false);
    }
  }, [client2logo.section_setting]);

  return (
    <>
      {client2logo.section_setting == true ? (
        <div>
          <div className="domain-section mt-5 pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="domain-text mt-5 pt-5">
                  <h2>{client2logo.expertise_title}</h2>
                </div>
                <div className="row row-cols-lg-6 align-items-center gx-md-5 mt-5">
                  {client2logo.expertise_section &&
                    client2logo.expertise_section.map((res, index) => {
                      return (
                        <div className="col">
                          <div className="domain-inner-logo text-center mb-5 mt-4">
                            {isLoading === true ? (
                              <Imgloader />
                            ) : (
                              <>
                                <img src={res.expertise_logo.url} title={res.expertise_name} className="img-fluid" alt={res.expertise_logo.title} />
                                <p>{res.expertise_name}</p>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default ClientLogotwo;
