import React, { useEffect, useState } from "react";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";
import { Link } from "react-router-dom";

const Aboutsectionone = (props) => {
  const { about1data } = props;
  const { about1datainner } = props;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (about1data.section_settings === true) {
      setIsLoading(false);
    }
  }, [about1data.section_settings]);

  console.log("about1data", about1data);
  return (
    <>
      {about1data.section_settings === true ? (
        <div className="pt-5 pb-md-5 pb-3">
          <div className="container pt-5 pb-5">
            <div className="row gx-md-5 pt-5 pb-md-5 align-items-center">
              <div className="col-md-7 col-12">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <>
                    <p className="list-link">
                      {about1datainner.title.rendered}
                    </p>
                    <h2 className="fw-bold page-title">
                      {about1data.banner_title}
                    </h2>
                    <p className="since-title">{about1data.banner_sub_title}</p>
                    <div
                      className="atlas-paragraph"
                      dangerouslySetInnerHTML={{
                        __html: about1data.banner_content,
                      }}
                    />
                    <Link
                      className="btn button-com link-animations"
                      to={about1data?.banner_button?.url}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.899"
                        height="19.555"
                        viewBox="0 0 11.899 19.555"
                      >
                        <path
                          id="Path_1094"
                          data-name="Path 1094"
                          d="M1122.005,1674.389l7.656,7.656-7.656,7.656"
                          transform="translate(-1119.884 -1672.268)"
                          fill="none"
                          stroke="#fff"
                        />
                      </svg>
                      {about1data?.banner_button?.title}
                    </Link>
                  </>
                )}
              </div>
              <div className="col-md-5 col-12">
                {isLoading ? (
                  <Imgloader />
                ) : (
                  <img
                    src={about1data?.banner_image?.url}
                    className="doodles-image"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Aboutsectionone;
