import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ClientLogoone from "./clientlogopage/ClientLogoone";
import ClientLogotwo from "./clientlogopage/ClientLogotwo";
import Loader from "./Loader";
import "../src/ClientLogo.css";
import { apiCall } from "./authentication";
import CommonCta from "./CommonCta";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const ClientLogo = () => {
  const [users2, setUsers2] = useState([]);
  const [clientlogosection1, setClientlogosection1] = useState([]);
  const [clientlogosection2, setClientlogosection2] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [yoastData, setYoastData] = useState({});

  let finalreacturl;

  const slug1 = "wp/v2/pages?slug=our-clients&acf_format=standard";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchLogo = async () => {
      try {
        const data = await apiCall(slug1, type);
        setUsers2(data);
        setClientlogosection1(data[0].acf.client_section[0]);
        setClientlogosection2(data[0].acf.client_section[1]);
        setYoastData(data[0].yoast_head_json);
        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout == "banner_section") {
            setClientlogosection1(element);
          }
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching article:", error);
      }
    };

    fetchLogo();
  }, []); // Empty dependency array ensures the effect runs once

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    finalreacturl = newurl.slice(0, newurl.lastIndexOf('/'))
  }

  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-12">
                <Conloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <ClientLogoone client1logo={clientlogosection1} data={users2} />
            <ClientLogotwo client2logo={clientlogosection2} />
            <CommonCta />
          </div>
        )}
      </div>
    </>
  );
};

export default ClientLogo;
