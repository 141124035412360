import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import Button from "./button/button";
import { apiCall, imageUpdate } from "./authentication";
import Imgloader from "./componentsheaderfooter/Imgloader";
import Conloader from "./componentsheaderfooter/Conloader";

const CommonCta = () => {
  const [ctadata, setCtadata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const slug = "options/all?_fields=footer_cta_section";
  const type = "get";
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const data = await apiCall(slug, type);
        setCtadata(data?.footer_cta_section);
        setIsLoading(false);
      } catch (error) {
        // Handle API error
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Empty dependency array ensures the effect runs once
  const handleLinkClick = () => {
    // Assuming tidioChatApi is globally accessible
    if (window.tidioChatApi) {
      window.tidioChatApi.display(true);
      window.tidioChatApi.open();
    }
  };
  setTimeout(() => {
    imageUpdate();
  }, 1000);
  return (
    <>
      <div className="graybg commoncta section-spacing">
        <div className="container">
          <div className="row gx-lg-0 align-items-center dot-animation">
            <div className="col-xl-4 col-sm-4 offset-sm-2 col-8 offset-2 mb-xl-0 mb-3 order-sm-2">
              {isLoading ? (
                <Imgloader />
              ) : (
                <>
                  {ctadata.section_setting === true ? (
                    <img
                      src={ctadata.cta_image}
                      className="img-fluid w-100 commoncta-img doodles-image do"
                      alt={ctadata.cta_image.alt}
                    />) : (
                    <div></div>
                  )}
                </>
              )}
            </div>
            <div className="col-xl-6 col-sm-6 text-sm-start text-center col-12 py-md-0 py-2 pb-sm-0">
              {isLoading ? (
                <Conloader />
              ) : (
                <>
                  {ctadata.section_setting === true ? (
                    <div>
                      <div className="d-flex dot-flex-testi mb-4 justify-content-sm-start justify-content-center">
                        <div className="dot-testi light-green-testi"></div>
                        <div className="dot-testi green-testi"></div>
                        <div className="dot-testi blue-testi"></div>
                      </div>
                      <div className="f94 mb-4">{ctadata.cta_title}</div>
                      <div className="page-title fw-bold">
                        {ctadata.cta_sub_title}
                      </div>
                      <div onClick={handleLinkClick}>
                        <Button
                          classadd="mt-4"
                          class="dark-bg"
                          link="javascript:;"
                          name={ctadata.cta_link.title}
                        />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonCta;
