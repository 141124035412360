import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Conloader from "../componentsheaderfooter/Conloader";
const InfographicsArticleDetailtwo = ({ data }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);
  return (
    <>
      <div className="section-spacing pt-0 pb-0">
        <div className="container">
          <div className="row potision-relative align-items-start">
            <div className="col-lg-8">
              {isLoading ? (
                <Conloader />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: data[0]?.content?.rendered }}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfographicsArticleDetailtwo;
