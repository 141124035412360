import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CareersOne from "./careerspage/CareersOne";
import CareersTwo from "./careerspage/CareersTwo";
import CareersThree from "./careerspage/CareersThree";
import CareersFour from "./careerspage/CareersFour";
import CareersFive from "./careerspage/CareersFive";
import Loader from "./Loader";
import CareerForm from "./Form/CareerFrom";
import { apiCall, imageUpdate } from "./authentication";
import "../src/Careers.css";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const Careers = () => {
  const [users8, setUsers8] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [careersection1, setCareersection1] = useState([]);
  const [careersection2, setCareersection2] = useState([]);
  const [job, setJob] = useState([]);
  const [career, setCareer] = useState([]);
  const [careersection4, setCareersection4] = useState([]);
  const [careersection3, setCareersection3] = useState([]);
  const [careersection5, setCareersection5] = useState([]);
  const [datapartner, setDatapartner] = useState([]);
  const [yoastData, setYoastData] = useState({});
  const slug1 = "wp/v2/career?_embed";
  const slug2 = "wp/v2/pages?slug=careers&acf_format=standard";
  const type = "get";

  const fetchJobs = async () => {
    try {
      const data = await apiCall(slug1, type);
      setJob(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchCareer = async () => {
      try {
        const data = await apiCall(slug2, type);
        setUsers8(data);
        setCareersection1(data[0].acf.common_section[0]);
        setCareersection4(data[0].acf.career_page_section[1]);
        setCareersection2(data[0]?.acf.career_page_section[0].section_setting);
        setCareersection3(data[0]?.acf.career_page_section[0]);
        setCareersection5(data[0]?.acf.common_section[1]);
        setYoastData(data[0].yoast_head_json);
        setCareer(data[0]?.acf?.career_page_section[0]);

        data[0].acf.common_section.forEach((element) => {
          if (element.acf_fc_layout == "banner_section") {
            setCareersection1(element);
          }
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchJobs();
    fetchCareer();
  }, []);

  setTimeout(() => {
    imageUpdate();
  }, 1000);

  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-md-6">
                <Conloader />
              </div>
              <div className="col-md-6">
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={yoastData.og_url}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <CareersThree
              careers3data={careersection3}
              careers1data={careersection1}
            />
            <CareersTwo careers9data={careersection2} careers2data={job} />
            <CareersOne
              careers1data={careersection1}
              careers3data={careersection3}
            />
            <CareersFour careers4data={careersection4} />
            <CareersFive career5data={careersection5} />
            <div
              class="modal fade"
              id="FormModal"
              tabindex="-1"
              aria-labelledby="FormModal"
              aria-hidden="true"
            >
              <CareerForm />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Careers;
