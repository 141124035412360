import React, { useState, useEffect } from "react";
import BlogDetailone from "./blogdetailpage/BlogDetailone";
import BlogDetailtwo from "./blogdetailpage/BlogDetailtwo";
import "../src/BlogDetail.css";
import { useParams } from "react-router-dom";
import BlogDetailthree from "./blogdetailpage/BlogDetailthree";
import CommonCta from "./CommonCta";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import { apiCall } from "./authentication";
import Loader from "./Loader";
import Conloader from "./componentsheaderfooter/Conloader";
import Imgloader from "./componentsheaderfooter/Imgloader";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState([]);
  const [blogdata, setBlogData] = useState([]);
  const [yoastData, setYoastData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  let finalreacturl;

  const slug1 = `wp/v2/posts/?slug=${slug}&_embed`;
  const slug2 = `relatedblog/post?_embed`;
  const type = "get";
  const fetchPost = async (slug) => {
    setIsLoading(true);
    try {
      const data = await apiCall(slug1, type);
      setBlogPost(data);
      setYoastData(data[0].yoast_head_json);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching Blog:", error);
    }
  };

  const fetchBlog = async () => {
    try {
      const data = await apiCall(slug2, type);
      setBlogData(data);
    } catch (error) {
      console.error("Error fetching Blog:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    // Assuming 'id' is defined elsewhere in your component
    fetchPost(slug);
    fetchBlog();
  }, [slug]);

  if (yoastData.og_url !== undefined) {
    const wpurl = yoastData.og_url.toString()
    const newurl = wpurl.replace('wpatlas', 'www');
    const newurl1 = newurl.replace('.com/', '.com/blog/');
    finalreacturl = newurl1.slice(0, newurl1.lastIndexOf('/'))
  }
  return (
    <>
      <div>
        {isLoading ? (
          <div className="container">
            <div className="row height-animation align-items-center">
              <div className="col-12">
                <Conloader />
                <Imgloader />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <HelmetProvider>
              <Helmet>
                <title>{yoastData.title}</title>
                <meta name="description" content={yoastData.description} />
                <link rel="canonical" hreflang="en" href={finalreacturl} />
                <meta
                  property="og:locale"
                  content="en_US"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:type"
                  content="article"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:title"
                  content={yoastData.og_title}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:description"
                  content={yoastData.og_description}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:url"
                  content={finalreacturl}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:site_name"
                  content={yoastData.og_site_name}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="article:modified_time"
                  content={yoastData.article_modified_time}
                  className="yoast-seo-meta-tag"
                />
                <meta
                  property="og:image"
                  content={yoastData.url}
                  className="yoast-seo-meta-tag"
                />
                {/* <meta property="og:image:width" content={yoastData.og_image.width} className="yoast-seo-meta-tag" />
                    <meta property="og:image:height" content={yoastData.og_image.height} className="yoast-seo-meta-tag" /> */}
                {/* <meta property="og:image:type" content={yoastData.og_image.type} className="yoast-seo-meta-tag" />
                    <meta name="twitter:card" content={yoastData.twitter_card} className="yoast-seo-meta-tag" /> */}
                <meta
                  name="twitter:label1"
                  content="Est. reading time"
                  className="yoast-seo-meta-tag"
                />
                <meta
                  name="twitter:data1"
                  content="1 minute"
                  className="yoast-seo-meta-tag"
                />
              </Helmet>
            </HelmetProvider>
            <BlogDetailone data={blogPost} />
            <BlogDetailtwo data={blogPost} />
            <BlogDetailthree article={blogdata} />
            <CommonCta />
          </div>
        )}
      </div>
    </>
  );
};

export default BlogDetail;
