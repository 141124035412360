import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import gsap, { TweenMax, Power0, TimelineMax } from "gsap";
import * as ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Conloader from "../componentsheaderfooter/Conloader";
import Imgloader from "../componentsheaderfooter/Imgloader";

function HireInnertwo(props) {
  const { hire2inner } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ScrollMagicPluginGsap(ScrollMagic, TweenMax);
    const controller = new ScrollMagic.Controller();
    const scene = new ScrollMagic.Scene({
      triggerElement: ".accredited-by-section",
      triggerHook: 0.8, // Adjust this value based on when you want the animation to start
      duration: "50%",
      reverse: true,
    });
    var animationTimeline = new TimelineMax();
    animationTimeline.to(
      $(".hireinneranimation2"),
      {
        x: 0, // Adjust the value as needed
        opacity: 1,
        ease: Power0.easeNone,
      },
      0
    );
    // Set the timeline as the tween for the ScrollMagic scene
    scene.setTween(animationTimeline);
    // Add the scene to the controller
    scene.addTo(controller);

    return () => {
      controller.destroy();
    };
  }, []);

  useEffect(() => {
    if (hire2inner.section_setting === true) {
      setIsLoading(false);
    }
  }, [hire2inner.section_setting]);

  return (
    <>
      {hire2inner.section_setting && hire2inner.section_setting === true ? (
        <section className="accredited-by-section section-spacing">
          <div className="container">
            <div className="d-flex justify-content-between hireinneranimation2">
              <div className="title-box">
                {isLoading ? (
                  <Conloader />
                ) : (
                  <h2>{hire2inner.accredited_title}</h2>
                )}
              </div>
              <div className="accredited-logo hireinneranimation2">
                <ul className="d-flex">
                  {hire2inner.accredited_logos &&
                    hire2inner.accredited_logos.map((res, index) => {
                      return (
                        <>
                          <li>
                            {isLoading ? (
                              <Imgloader />
                            ) : (
                              <img src={res.accreditedlogo} alt="" />
                            )}
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default HireInnertwo;
